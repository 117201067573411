import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider } from '@mui/material/styles'
import FirebaseProvider from './utils/firebase'
import App from './components/App/'
import ColorThemeProvider from './contexts/ColorThemeContext'
import { EnhancerProvider } from './contexts/EnhancerContext'

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ColorThemeProvider>
      <FirebaseProvider>
        <EnhancerProvider>
          <CssBaseline />
          <App />
        </EnhancerProvider>
      </FirebaseProvider>
    </ColorThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
)