import React, { Fragment } from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'
import { useEnhancerPlatforms } from '../../contexts/EnhancerContext'

import { API_ROOT_URL } from '../../constants/'
import * as ROUTES from '../../constants/routes'

import hyrosLogoImg from '../../media/hyros_logo.png'
import rechargeLogoImg from '../../media/recharge_logo.png'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
  paper: {
    padding: theme.spacing(2),
  },
  syncButton: {
    width: 100,
  },
  logoImg: {
    width: 50,
  },
}))

const Enhancers = () => {
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const history = useHistory()
  const { fetchEnhancerPlatforms } = useEnhancerPlatforms()

  const [showMessage, setShowMessage] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [messageSeverity, setMessageSeverity] = React.useState('success')

  // Hyros
  const [showHyrosDialog, setShowHyrosDialog] = React.useState(false)
  const [accountIdHyros, setAccountIdHyros] = React.useState('')
  const [accountNameHyros, setAccountNameHyros] = React.useState('')
  const [apiKeyHyros, setApiKeyHyros] = React.useState('')

  // Recharge
  const [showRechargeDialog, setShowRechargeDialog] = React.useState(false)
  const [accountIdRecharge, setAccountIdRecharge] = React.useState('')
  const [accountNameRecharge, setAccountNameRecharge] = React.useState('')
  const [apiKeyRecharge, setApiKeyRecharge] = React.useState('')

  const {
    isLoading: isLoadingEnhancers,
    data: enhancers = []
  } = useQuery(['enhancers-list'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/enhancers`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )

  const { isLoading: isLoadingConnectHyros, refetch: connectHyros } = useQuery(['connect-hyros'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/enhancers/hyros/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          accountId: accountIdHyros,
          accountName: accountNameHyros,
          apiKey: apiKeyHyros,
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowHyrosDialog(false)
        setAccountIdHyros('')
        setAccountNameHyros('')
        setApiKeyHyros('')
        fetchEnhancerPlatforms()
        history.push(ROUTES.HYROS_ENHANCERS + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Hyros. Please try again and contact support if error persists.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectRecharge, refetch: connectRecharge } = useQuery(['connect-recharge'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/enhancers/recharge/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          accountId: accountIdRecharge,
          accountName: accountNameRecharge,
          apiKey: apiKeyRecharge,
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowRechargeDialog(false)
        setAccountIdRecharge('')
        setAccountNameRecharge('')
        setApiKeyRecharge('')
        fetchEnhancerPlatforms()
        history.push(ROUTES.RECHARGE_ENHANCERS + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Recharge. Please try again and contact support if error persists.')
        setMessageSeverity('error')
      }
    }
  )

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const handleCloseHyrosDialog = () => {
    setShowHyrosDialog(false)
    setAccountIdHyros('')
    setAccountIdHyros('')
    setApiKeyHyros('')
  }

  const handleCloseRechargeDialog = () => {
    setShowRechargeDialog(false)
    setAccountIdRecharge('')
    setAccountIdRecharge('')
    setApiKeyRecharge('')
  }

  return (
    <Container maxWidth='md'>

      {/* Hyros Enhancers */}
      <Box>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Hyros Enhancers
          </Typography>

          <List>
            {isLoadingEnhancers ?
              <Skeleton variant='rectangular' height={35} />
              : (enhancers.filter(e => e.platform === 'hyros').map(enhancer =>
                <Fragment key={enhancer.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.HYROS_ENHANCERS + `/${enhancer.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='hyros_logo'
                        src={hyrosLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={enhancer.nickname} secondary={enhancer.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowHyrosDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showHyrosDialog}
            onClose={handleCloseHyrosDialog}
          >
            <DialogTitle>
              Connect with Hyros
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                The <b>Master Email</b> is used as the ID of your Hyros acccount. This the email address that was used to create
                the Hyros account that is used as the master login for the account. Because this email can only
                be associated with a single account, it serves as a unique identifier for your account.
                <br />
                <br />
                The <b>Account Name</b> is the name of your Hyros acccount. This value can be seen in the sub-text
                below your profile name in the top right corner when logged in.
                <br />
                <br />
                The <b>API Key</b> is needed to connect with Hyros. You will first need to create an API Key in your Hyros account
                with roles of <b>Tracking</b> and <b>Sales Data</b> added to it. After creating the API Key, insert the value below.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Master Email'
                  name='hyros-account-id'
                  value={accountIdHyros}
                  onChange={(event) => setAccountIdHyros(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Account Name'
                  name='hyros-account-name'
                  value={accountNameHyros}
                  onChange={(event) => setAccountNameHyros(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='API Key'
                  name='hyros-api-key'
                  value={apiKeyHyros}
                  onChange={(event) => setApiKeyHyros(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseHyrosDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectHyros()}
                color='secondary'
                disabled={!accountIdHyros || !accountNameHyros || !apiKeyHyros}
              >
                {isLoadingConnectHyros ? <CircularProgress color='inherit' size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </Dialog>

        </Paper>
      </Box>

      {/* Recharge Enhancers */}
      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Recharge Enhancers
          </Typography>

          <List>
            {isLoadingEnhancers ?
              <Skeleton variant='rectangular' height={35} />
              : (enhancers.filter(e => e.platform === 'recharge').map(enhancer =>
                <Fragment key={enhancer.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.RECHARGE_ENHANCERS + `/${enhancer.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='recharge_logo'
                        src={rechargeLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={enhancer.nickname} secondary={enhancer.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowRechargeDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showRechargeDialog}
            onClose={handleCloseRechargeDialog}
          >
            <DialogTitle>
              Connect with Recharge
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                The <b>Account ID</b> is used as the ID of your Recharge acccount. You can find this in the URL when logged in to Recharge.
                The URL is formatted like this: <b>https://ACCOUNT_ID.admin.rechargeapps.com</b>. Enter this ACCOUNT_ID
                value from the URL below. This value is unique to your Recharge account.
                <br />
                <br />
                The <b>Account Name</b> is the name of your Recharge acccount. This value can be seen in the bottom left
                by your profile icon when logged in, right above your Shopify store URL and the Log out button.
                <br />
                <br />
                The <b>API Key</b> is needed to connect with Recharge. You will first need to create an API Key in your Recharge account
                by navigating to <b>Tools & apps</b> &gt; <b>API tokens</b>. The API Key should have the
                following scopes: <b>read customers</b>, <b>read discounts</b>, <b>read orders</b>, <b>read products</b>, and <b>read subscriptions</b>.
                After creating the API Key, insert the value below.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Account ID'
                  name='recharge-account-id'
                  value={accountIdRecharge}
                  onChange={(event) => setAccountIdRecharge(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Account Name'
                  name='recharge-account-name'
                  value={accountNameRecharge}
                  onChange={(event) => setAccountNameRecharge(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='API Key'
                  name='recharge-api-key'
                  value={apiKeyRecharge}
                  onChange={(event) => setApiKeyRecharge(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseRechargeDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectRecharge()}
                color='secondary'
                disabled={!accountIdRecharge || !accountNameRecharge || !apiKeyRecharge}
              >
                {isLoadingConnectRecharge ? <CircularProgress color='inherit' size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </Dialog>

        </Paper>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={5000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default Enhancers