import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import InputBase from '@mui/material/InputBase'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { API_ROOT_URL } from '../../constants'
import * as ROUTES from '../../constants/routes'

import committedLogoImg from '../../media/committed_logo.png'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
  logoImg: {
    width: 80,
  },
  syncButton: {
    width: 110,
  },
  redButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
}))

const REVOKE_SUCCESS_MESSAGE = 'Account removed. Data will no longer be synced from this account. Redirecting back to settings...'
const WEBHOOK_URL_BASE = `https://us-central1-ltv-numbers-${process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev'}.cloudfunctions.net/webhooks/committed/`
const CONFIG_KEY_PAYMENT_PERCENT = 'payment_percent'

const CommittedSource = () => {
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const firebase = React.useContext(FirebaseContext)

  const [webhookUrl, setWebhookUrl] = React.useState('')
  const [showCopyTooltipWebhook, setShowCopyTooltipWebhook] = React.useState(false)

  const [message, setMessage] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [messageSeverity, setMessageSeverity] = React.useState('success')
  const [paymentPercent, setPaymentPercent] = React.useState(1)
  const [showRevokeConfirmation, setShowRevokeConfirmation] = React.useState(false)

  React.useEffect(() => {
    const uid = firebase.auth().currentUser.uid
    setWebhookUrl(WEBHOOK_URL_BASE + uid + '/integration/' + id)
  }, [])

  const {
    isLoading: isLoadingIntegration,
    data: integration = {}
  } = useQuery(['integration-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.config) {
          const config = JSON.parse(data.config)
          if (config[CONFIG_KEY_PAYMENT_PERCENT]) {
            setPaymentPercent(config[CONFIG_KEY_PAYMENT_PERCENT])
          }
        }
      }
    }
  )


  const { isLoading: isLoadingRevoke, refetch: revoke } = useQuery(['revoke-committed'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/custom/revoke`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnReconnect: false,
      onSuccess: () => {
        setShowRevokeConfirmation(false)
        setMessage(REVOKE_SUCCESS_MESSAGE)
        setShowMessage(true)
        setTimeout(() => history.push(ROUTES.SETTINGS), 3000)
      },
    }
  )

  const { isLoading: isUpdatingConfig, mutate: updatePaymentPercent } = useMutation(
    newPaymentPercent => firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/committed/config`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id,
          key: CONFIG_KEY_PAYMENT_PERCENT,
          value: newPaymentPercent
        }),
      }).then(res => res.json())
    }),
    {
      onSuccess: () => {
        setShowMessage(true)
        setMessage('Data Source successfully updated')
        setMessageSeverity('success')
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error updating data source. Please try again, and if problem persists, contact support.')
        setMessageSeverity('error')
      }
    }
  )

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    setShowCopyTooltipWebhook(true)
  }

  return (
    <div className={classes.root}>
      <Box padding={2}>
        <Button
          variant='outlined'
          onClick={() => history.push(ROUTES.SETTINGS)}
          size='small'
        >
          Back
        </Button>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                  <img
                    className={classes.logoImg}
                    alt='committed_logo'
                    src={committedLogoImg}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='h5'>
                    Committed Source
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        {/* Name */}
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : `${integration.nickname}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    ID
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : `${integration.platform_id}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        {/* Payment Percentage */}
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Sale Percentage
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' paragraph>
                    In order to properly set the amount you earn on each sale, please enter the percentage below that represents your percentage
                    earned from the <b>Gross Sale</b> amount.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    LTV Numbers receives the only the Gross Sale amount and we use the percentage you provide to calculate your earnings. We understand that
                    this number can vary slightly from sale to sale, but Committed is unable to give us your earnings directly due to limitations on how they
                    calculate this exact number at the end of each month.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    Therefore, this is meant to be a best estimate, with the shared understanding that it will not be exact. We recommend looking
                    at all of your historical data with Committed, and divide your earnings by the gross sales to get an overall average.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    Note that this number can be updated at any time, but that update will only apply to future sales.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    If you have questions about this, please contact your LTV Numbers account manager.
                  </Typography>
                    {isLoadingIntegration ? <Skeleton variant='rect' width={300} /> : (
                      <Box marginTop={0} display='flex' alignItems='center'>
                        <FormControl
                          variant='outlined'
                          margin='dense'
                          sx={{ margin: 1, minWidth: 150 }}
                          required
                        >
                          <InputLabel htmlFor='payment-percent'>Your Sale Percentage</InputLabel>
                          <OutlinedInput
                            label='Your Sale Percentage'
                            id='payment-percent'
                            name='payment-percent'
                            value={paymentPercent*100}
                            onChange={(event) => setPaymentPercent(event.target.value / 100)}
                            endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                            variant='outlined'
                            type='number'
                            size='small'
                            required
                          />
                        </FormControl>

                        <Button
                          variant='contained'
                          color='secondary'
                          onClick={() => updatePaymentPercent(paymentPercent)}
                          disabled={!paymentPercent || isUpdatingConfig}
                        >
                          {isUpdatingConfig ?
                            <CircularProgress color='inherit' size={24} />
                            : 'Update'
                          }
                        </Button>
                      </Box>
                    )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        {/* Webhooks */}
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Webhooks
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box>
                    <Box display='block'>
                      <Typography variant='body2' paragraph>
                        The webhook URL is the destination URL that data can be sent to. LTV Numbers and Committed have collaborated
                        to create this integration and have an agreed upon data structure that we adhere to.
                      </Typography>
                      <Typography variant='body2' paragraph>
                        If you are setting up this integration yourself, you can reach out to the Committed team and let them know you
                        would like to integrate with LTV Numbers. Provide them with the webhook URL below, and they will configure
                        the integration on their end to start sending data.
                      </Typography>
                    </Box>
                    {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <InputBase
                          variant='filled'
                          value={webhookUrl}
                          size='small'
                          fullWidth
                          disabled
                        />
                        <Tooltip
                          open={showCopyTooltipWebhook}
                          onClose={() => setShowCopyTooltipWebhook(false)}
                          leaveDelay={2000}
                          placement='right'
                          title='Copied'
                        >
                          <Button
                            variant='text'
                            color='primary'
                            onClick={() => copyToClipboard(webhookUrl)}
                          >
                            Copy
                          </Button>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Delete
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' paragraph>
                    If you want to remove this data integration so transacations can no longer be saved, click the button below.
                    It is recommended that you also reach out to Committed to let them know you would like them to delete the
                    integration on their side.
                  </Typography>
                  <Button
                    className={classes.redButton}
                    variant='text'
                    onClick={() => setShowRevokeConfirmation(true)}
                  >
                    Delete Source
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Dialog
        open={showRevokeConfirmation}
        onClose={() => setShowRevokeConfirmation(false)}
      >
        <DialogTitle>
          Remove access to account <b>&quot;{integration.nickname}&quot;</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will prevent data from being synced to LTV Numbers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.redButton}
            variant='text'
            onClick={() => setShowRevokeConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => revoke()}
          >
            {isLoadingRevoke ? <CircularProgress color='inherit' size={24} /> : 'Remove'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={10000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

CommittedSource.propTypes = {}

export default CommittedSource