import React, { Fragment } from 'react'
import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import FormControl from '@mui/material/FormControl'
import InputBase from '@mui/material/InputBase'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { API_ROOT_URL, SITE_ROOT_URL } from '../../constants/'
import * as ROUTES from '../../constants/routes'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

import connectWithStripeImg from '../../media/connect_with_stripe.png'
import baskhealthLogoImg from '../../media/baskhealth_logo.png'
import buygoodsLogoImg from '../../media/buygoods_logo.png'
import clickbankLogoImg from '../../media/clickbank_logo.png'
import clickfunnelsLogoImg from '../../media/clickfunnels_logo.png'
import committedLogoImg from '../../media/committed_logo.png'
import ltvLogoImg from '../../media/ltv_logo.png'
import digistore24LogoImg from '../../media/digistore24_logo.png'
import easywebinarLogoImg from '../../media/easywebinar_logo.png'
import elementorLogoImg from '../../media/elementor_logo.png'
import highlevelLogoImg from '../../media/highlevel_logo.png'
import kajabiLogoImg from '../../media/kajabi_logo.png'
import keapLogoImg from '../../media/keap_logo.png'
import kitLogoImg from '../../media/kit_logo.png'
import konnektiveLogoImg from '../../media/konnektive_logo.png'
import maropostLogoImg from '../../media/maropost_logo.png'
import ontraportLogoImg from '../../media/ontraport_logo.png'
import paypalLogoImg from '../../media/paypal_logo.png'
import samcartLogoImg from '../../media/samcart_logo.png'
import shopifyLogoImg from '../../media/shopify_logo.png'
import stickyLogoImg from '../../media/sticky_logo.png'
import stripeLogoImg from '../../media/stripe_logo.png'
import ultracartLogoImg from '../../media/ultracart_logo.png'
import woocommerceLogoImg from '../../media/woocommerce_logo.png'

const KEAP_CLIENT_ID = process.env.REACT_APP_KEAP_CLIENT_ID
const STRIPE_CLIENT_ID = process.env.REACT_APP_STRIPE_CLIENT_ID
const STRIPE_REDIRECT_URI = SITE_ROOT_URL + ROUTES.STRIPE_OAUTH

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
  paper: {
    padding: theme.spacing(2),
  },
  syncButton: {
    width: 100,
  },
  logoImg: {
    width: 50,
  },
}))

const FUNNEL_ADDED_SUCCESS_MESSAGE = 'Funnel added successfully.'
const WEBHOOK_BUCKET_ZAPIER_URL_BASE = `https://us-central1-ltv-numbers-${process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev'}.cloudfunctions.net/webhooks/bucket-zapier/`
const WEBHOOK_DROPFUNNELS_URL_BASE = `https://us-central1-ltv-numbers-${process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev'}.cloudfunctions.net/webhooks/dropfunnels/`
const WEBHOOK_SAMCART_URL_BASE = `https://us-central1-ltv-numbers-${process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev'}.cloudfunctions.net/webhooks/samcart/`

const DataSources = () => {
  const classes = useStyles()
  const firebase = React.useContext(FirebaseContext)
  const history = useHistory()
  const [showMessage, setShowMessage] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [messageSeverity, setMessageSeverity] = React.useState('success')
  const [showCopyTooltip, setShowCopyTooltip] = React.useState(false)

  // Custom
  const [showCustomDialog, setShowCustomDialog] = React.useState(false)
  const [idCustom, setIdCustom] = React.useState('')
  const [nameCustom, setNameCustom] = React.useState('')

  // Bask Health
  const [showBaskHealthDialog, setShowBaskHealthDialog] = React.useState(false)
  const [accountIdBaskHealth, setAccountIdBaskHealth] = React.useState('')
  const [accountNameBaskHealth, setAccountNameBaskHealth] = React.useState('')

  // BuyGoods
  const [showBuygoodsDialog, setShowBuygoodsDialog] = React.useState(false)
  const [accountIdBuygoods, setAccountIdBuygoods] = React.useState('')
  const [accountNameBuygoods, setAccountNameBuygoods] = React.useState('')
  const [accountTimezoneBuygoods, setAccountTimezoneBuygoods] = React.useState(TIMEZONE)

  // Clickbank
  const [showClickbankDialog, setShowClickbankDialog] = React.useState(false)
  const [accountNicknameClickbank, setAccountNicknameClickbank] = React.useState('')
  const [secretKeyClickbank, setSecretKeyClickbank] = React.useState('')

  // Clickfunnels
  const [showClickfunnelsDialog, setShowClickfunnelsDialog] = React.useState(false)
  const [accountUrlClickfunnels, setAccountUrlClickfunnels] = React.useState('')
  const [accountNameClickfunnels, setAccountNameClickfunnels] = React.useState('')

  // Committed
  const [showCommittedDialog, setShowCommittedDialog] = React.useState(false)
  const [accountIdCommitted, setAccountIdCommitted] = React.useState('')
  const [accountNameCommitted, setAccountNameCommitted] = React.useState('')

  // Digistore24
  const [showDigistore24Dialog, setShowDigistore24Dialog] = React.useState(false)
  const [accountIdDigistore24, setAccountIdDigistore24] = React.useState('')
  const [apiKeyDigistore24, setApiKeyDigistore24] = React.useState('')

  // DropFunnels Add Funnel Dialog
  const [showFunnelDialogDropfunnels, setShowFunnelDialogDropfunnels] = React.useState(false)
  const [funnelNicknameDropfunnels, setFunnelNicknameDropfunnels] = React.useState('')
  const [funnelUrlDropfunnels, setFunnelUrlDropfunnels] = React.useState('')
  const [webhookUrlDropfunnels, setWebhookUrlDropfunnels] = React.useState('')
  const [funnelPlatformIdDropfunnels, setFunnelPlatformIdDropfunnels] = React.useState('')
  const [showCopyTooltipDropfunnels, setShowCopyTooltipDropfunnels] = React.useState(false)

  // Easy Webinar
  const [showEasyWebinarDialog, setShowEasyWebinarDialog] = React.useState(false)
  const [nicknameEasyWebinar, setNicknameEasyWebinar] = React.useState('')
  const [accountUsernameEasyWebinar, setAccountUsernameEasyWebinar] = React.useState('')

  // Highlevel
  const [showHighlevelDialog, setShowHighlevelDialog] = React.useState(false)
  const [locationIdHighlevel, setLocationIdHighlevel] = React.useState('')
  const [businessNameHighlevel, setBusinessNameHighlevel] = React.useState('')

  // Kajabi Dialog
  const [showKajabiDialog, setShowKajabiDialog] = React.useState(false)
  const [siteIdKajabi, setSiteIdKajabi] = React.useState('')
  const [siteNameKajabi, setSiteNameKajabi] = React.useState('')

  // Kit Dialog
  const [showKitDialog, setShowKitDialog] = React.useState(false)
  const [apiKeyKit, setApiKeyKit] = React.useState('')
  const [accountNameKit, setAccountNameKit] = React.useState('')
  const [accountIdKit, setAccountIdKit] = React.useState('')

  // Konnektive Dialog
  const [showKonnektiveDialog, setShowKonnektiveDialog] = React.useState(false)
  const [nicknameKonnektive, setNicknameKonnektive] = React.useState('')
  const [usernameKonnektive, setUsernameKonnektive] = React.useState('')
  const [loginIdKonnektive, setLoginIdKonnektive] = React.useState('')
  const [passwordKonnektive, setPasswordKonnektive] = React.useState('')

  // Maropost
  const [showMaropostDialog, setShowMaropostDialog] = React.useState(false)
  const [nicknameMaropost, setNicknameMaropost] = React.useState('')
  const [accountIdMaropost, setAccountIdMaropost] = React.useState('')

  // Ontraport
  const [showOntraportDialog, setShowOntraportDialog] = React.useState(false)
  const [nicknameOntraport, setNicknameOntraport] = React.useState('')
  const [appIdOntraport, setAppIdOntraport] = React.useState('')
  const [apiKeyOntraport, setApiKeyOntraport] = React.useState('')

  // Paypal Dialog
  const [showPaypalDialog, setShowPaypalDialog] = React.useState(false)
  const [clientIdPaypal, setClientIdPaypal] = React.useState('')
  const [secretPaypal, setSecretPaypal] = React.useState('')

  // SamCart
  const [showSamCartDialog, setShowSamCartDialog] = React.useState(false)
  const [accountIdSamCart, setAccountIdSamCart] = React.useState('')
  const [marketplaceNameSamCart, setMarketplaceNameSamCart] = React.useState('')
  const [apiKeySamCart, setApiKeySamCart] = React.useState('')
  const [webhookUrlSamCart, setWebhookUrlSamCart] = React.useState('')

  // Sticky Dialog
  const [showStickyDialog, setShowStickyDialog] = React.useState(false)
  const [accountNameSticky, setAccountNameSticky] = React.useState('')
  const [accountIdSticky, setAccountIdSticky] = React.useState('')
  const [apiUsernameSticky, setApiUsernameSticky] = React.useState('')
  const [apiPasswordSticky, setApiPasswordSticky] = React.useState('')
  const [accountTimezoneSticky, setAccountTimezoneSticky] = React.useState(TIMEZONE)

  // Ultracart Dialog
  const [showUltracartDialog, setShowUltracartDialog] = React.useState(false)
  const [accountNicknameUltracart, setAccountNicknameUltracart] = React.useState('')
  const [merchantIdUltracart, setMerchantIdUltracart] = React.useState('')
  const [apiKeyUltracart, setApiKeyUltracart] = React.useState('')

  // Woocommerce Dialog
  const [showWoocommerceDialog, setShowWoocommerceDialog] = React.useState(false)
  const [storeUrlWoocommerce, setStoreUrlWoocommerce] = React.useState('')
  const [storeNameWoocommerce, setStoreNameWoocommerce] = React.useState('')
  const [consumerKeyWoocommerce, setConsumerKeyWoocommerce] = React.useState('')
  const [consumerSecretWoocommerce, setConsumerSecretWoocommerce] = React.useState('')

  // Elementor Dialog
  const [showElementorDialog, setShowElementorDialog] = React.useState(false)
  const [siteUrlWordpress, setSiteUrlWordpress] = React.useState('')
  const [siteNameWordpress, setSiteNameWordpress] = React.useState('')
  const [siteTimezoneWordpress, setSiteTimezoneWordpress] = React.useState(TIMEZONE)

  // Bucket Zapier Webhook
  const [webhookUrlBucketZapier, setWebhookUrlBucketZapier] = React.useState('')

  React.useEffect(() => {
    const uid = firebase.auth().currentUser.uid
    setWebhookUrlDropfunnels(WEBHOOK_DROPFUNNELS_URL_BASE + uid)
    setWebhookUrlSamCart(WEBHOOK_SAMCART_URL_BASE + uid)
    setWebhookUrlBucketZapier(WEBHOOK_BUCKET_ZAPIER_URL_BASE + uid)
  }, [])

  React.useEffect(() => {
    const re = /funnel_id=([0-9]*)&/
    const match = funnelUrlDropfunnels.match(re)
    if (match) {
      setFunnelPlatformIdDropfunnels(match[1])
    }
  }, [funnelUrlDropfunnels])

  const {
    isLoading: isLoadingIntegrations,
    data: integrations = []
  } = useQuery(['integrations-list'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )
  const sortedIntegrations = React.useMemo(() => {
    return (integrations || []).slice().sort((a, b) => a.nickname.localeCompare(b.nickname))
  }, [integrations])

  const addFunnelDropfunnels = () => {
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/funnels`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          platform_id: funnelPlatformIdDropfunnels,
          platform: 'dropfunnels',
          source: 'dropfunnels_webhook',
          nickname: funnelNicknameDropfunnels,
          source_of_truth: true,
        })
      }).then(res => {
        setFunnelNicknameDropfunnels('')
        setFunnelUrlDropfunnels('')
        setFunnelPlatformIdDropfunnels('')
        setShowMessage(true)
        setMessage(FUNNEL_ADDED_SUCCESS_MESSAGE)
        setMessageSeverity('success')
        setShowFunnelDialogDropfunnels(false)
        return res.json()
      })
    }),
    {
      enabled: false,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  }

  const { isLoading: isLoadingConnectCustom, refetch: connectCustom } = useQuery(['connect-custom'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/custom/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          id: idCustom,
          name: nameCustom,
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowCustomDialog(false)
        setIdCustom('')
        setNameCustom('')
        history.push(ROUTES.CUSTOM_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error creating custom integration. Please try again.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectBaskHealth, refetch: connectBaskHealth } = useQuery(['connect-baskhealth'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/baskhealth/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          accountId: accountIdBaskHealth,
          accountName: accountNameBaskHealth,
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowBaskHealthDialog(false)
        setAccountIdBaskHealth('')
        setAccountNameBaskHealth('')
        history.push(ROUTES.BASKHEALTH_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Bask Health. Check that the information provided is correct.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectBuygoods, refetch: connectBuygoods } = useQuery(['connect-buygoods'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/buygoods/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          accountId: accountIdBuygoods,
          accountName: accountNameBuygoods,
          timezone: accountTimezoneBuygoods,
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowBuygoodsDialog(false)
        setAccountIdBuygoods('')
        history.push(ROUTES.BUYGOODS_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with BuyGoods. Please try again and contact support if error persists.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectClickbank, refetch: connectClickbank } = useQuery(['connect-clickbank'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/clickbank/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          accountNickname: accountNicknameClickbank,
          secretKey: secretKeyClickbank
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowClickbankDialog(false)
        setAccountNicknameClickbank('')
        setSecretKeyClickbank('')
        history.push(ROUTES.CLICKBANK_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Clickbank. Check that all app permissions are set.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectClickfunnels, refetch: connectClickfunnels } = useQuery(['connect-clickfunnels'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/clickfunnels/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          accountUrl: accountUrlClickfunnels,
          accountName: accountNameClickfunnels,
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowClickfunnelsDialog(false)
        setAccountUrlClickfunnels('')
        setAccountNameClickfunnels('')
        history.push(ROUTES.CLICKFUNNELS_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Clickfunnels. Check that the provided information is correct and try again.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectCommitted, refetch: connectCommitted } = useQuery(['connect-committed'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/committed/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          id: accountIdCommitted,
          name: accountNameCommitted,
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowCommittedDialog(false)
        setAccountIdCommitted('')
        setAccountNameCommitted('')
        history.push(ROUTES.COMMITTED_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Committed. Check that the provided information is correct and try again.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectDigistore24, refetch: connectDigistore24 } = useQuery(['connect-digistore24'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/digistore24/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          accountId: accountIdDigistore24,
          apiKey: apiKeyDigistore24
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowDigistore24Dialog(false)
        setAccountIdDigistore24('')
        setApiKeyDigistore24('')
        history.push(ROUTES.DIGISTORE24_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Digistore24. Check that all app permissions are set.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectEasyWebinar, refetch: connectEasyWebinar } = useQuery(['connect-easywebinar'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/easywebinar/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          nickname: nicknameEasyWebinar,
          accountUsername: accountUsernameEasyWebinar,
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowEasyWebinarDialog(false)
        setNicknameEasyWebinar('')
        setAccountUsernameEasyWebinar('')
        history.push(ROUTES.EASYWEBINAR_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Easy Webinar. Check that the information provided is correct.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectHighlevel, refetch: connectHighlevel } = useQuery(['connect-highlevel'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/highlevel/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          locationId: locationIdHighlevel,
          businessName: businessNameHighlevel,
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowHighlevelDialog(false)
        setLocationIdHighlevel('')
        setBusinessNameHighlevel('')
        history.push(ROUTES.HIGHLEVEL_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Highlevel. Check that the provided information is correct and try again.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectKajabi, refetch: connectKajabi } = useQuery(['connect-kajabi'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/kajabi/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          siteId: siteIdKajabi,
          siteName: siteNameKajabi,
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowKajabiDialog(false)
        setSiteIdKajabi('')
        setSiteNameKajabi('')
        history.push(ROUTES.KAJABI_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Kajabi. Check that the provided information is correct and try again.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectKit, refetch: connectKit } = useQuery(['connect-kit'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/kit/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          apiKey: apiKeyKit,
          accountName: accountNameKit,
          accountId: accountIdKit,
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowKitDialog(false)
        setApiKeyKit('')
        setAccountNameKit('')
        setAccountIdKit('')
        history.push(ROUTES.KIT_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Kit. Check that the provided information is correct and try again.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectKonnektive, refetch: connectKonnektive } = useQuery(['connect-konnektive'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/konnektive/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          nickname: nicknameKonnektive,
          username: usernameKonnektive,
          loginId: loginIdKonnektive,
          password: passwordKonnektive
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowKonnektiveDialog(false)
        setNicknameKonnektive('')
        setUsernameKonnektive('')
        setLoginIdKonnektive('')
        setPasswordKonnektive('')
        history.push(ROUTES.KONNEKTIVE_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Konnektive. Check that the provided information is correct and try again.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectMaropost, refetch: connectMaropost } = useQuery(['connect-maropost'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/maropost/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          nickname: nicknameMaropost,
          accountId: accountIdMaropost,
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowMaropostDialog(false)
        setNicknameMaropost('')
        setAccountIdMaropost('')
        history.push(ROUTES.MAROPOST_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Maropost. Check that the information provided is correct.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectOntraport, refetch: connectOntraport } = useQuery(['connect-ontraport'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/ontraport/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          nickname: nicknameOntraport,
          appId: appIdOntraport,
          apiKey: apiKeyOntraport
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowOntraportDialog(false)
        setNicknameOntraport('')
        setAppIdOntraport('')
        setApiKeyOntraport('')
        history.push(ROUTES.ONTRAPORT_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Ontraport. Check that the information provided is correct.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectPaypal, refetch: connectPaypal } = useQuery(['connect-paypal'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/paypal/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          client_id: clientIdPaypal,
          secret: secretPaypal
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowPaypalDialog(false)
        setClientIdPaypal('')
        setSecretPaypal('')
        history.push(ROUTES.PAYPAL_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with PayPal. Check that all app permissions are set, or try again in a few minutes.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectSamCart, refetch: connectSamCart } = useQuery(['connect-samcart'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/samcart/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          accountId: accountIdSamCart,
          marketplaceName: marketplaceNameSamCart,
          apiKey: apiKeySamCart
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowSamCartDialog(false)
        setAccountIdSamCart('')
        setApiKeySamCart('')
        history.push(ROUTES.SAMCART_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with SamCart. Check that all app permissions are set.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectSticky, refetch: connectSticky } = useQuery(['connect-sticky'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/sticky/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          accountId: accountIdSticky,
          accountName: accountNameSticky,
          apiUsername: apiUsernameSticky,
          apiPassword: apiPasswordSticky,
          accountTimezone: accountTimezoneSticky
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowStickyDialog(false)
        setAccountIdSticky('')
        setAccountNameSticky('')
        setApiUsernameSticky('')
        setApiPasswordSticky('')
        history.push(ROUTES.STICKY_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Sticky. Check that the provided information is correct and try again.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectUltracart, refetch: connectUltracart } = useQuery(['connect-ultracart'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/ultracart/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          nickname: accountNicknameUltracart,
          merchantId: merchantIdUltracart,
          apiKey: apiKeyUltracart,
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowUltracartDialog(false)
        setAccountNicknameUltracart('')
        setMerchantIdUltracart('')
        setApiKeyUltracart('')
        history.push(ROUTES.ULTRACART_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with UltraCart. Check that the provided information is correct and try again.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectWoocommerce, refetch: connectWoocommerce } = useQuery(['connect-woocommerce'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/woocommerce/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          storeUrl: storeUrlWoocommerce,
          storeName: storeNameWoocommerce,
          consumerKey: consumerKeyWoocommerce,
          consumerSecret: consumerSecretWoocommerce
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowWoocommerceDialog(false)
        setStoreUrlWoocommerce('')
        setStoreNameWoocommerce('')
        setConsumerKeyWoocommerce('')
        setConsumerSecretWoocommerce('')
        history.push(ROUTES.WOOCOMMERCE_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with WooCommerce. Check that the provided information is correct and try again.')
        setMessageSeverity('error')
      }
    }
  )

  const { isLoading: isLoadingConnectElementor, refetch: connectElementor } = useQuery(['connect-elementor'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/elementor/connect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          siteUrl: siteUrlWordpress,
          siteName: siteNameWordpress,
          siteTimezone: siteTimezoneWordpress
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setShowElementorDialog(false)
        setSiteUrlWordpress('')
        setSiteNameWordpress('')
        history.push(ROUTES.ELEMENTOR_SOURCES + `/${data.id}`)
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error connecting with Elementor. Check that the provided information is correct and try again.')
        setMessageSeverity('error')
      }
    }
  )

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    setShowCopyTooltip(true)
  }

  const copyToClipboardDropfunnels = (text) => {
    navigator.clipboard.writeText(text)
    setShowCopyTooltipDropfunnels(true)
  }

  const handleCloseFunnelDialogDropfunnels = () => {
    setShowFunnelDialogDropfunnels(false)
    setFunnelNicknameDropfunnels('')
    setFunnelUrlDropfunnels('')
    setFunnelPlatformIdDropfunnels('')
  }

  const handleCloseBaskHealthDialog = () => {
    setShowBaskHealthDialog(false)
    setAccountIdBaskHealth('')
    setAccountNameBaskHealth('')
  }

  const handleCloseBuygoodsDialog = () => {
    setShowBuygoodsDialog(false)
    setAccountIdBuygoods('')
  }

  const handleCloseClickbankDialog = () => {
    setShowClickbankDialog(false)
    setAccountNicknameClickbank('')
    setSecretKeyClickbank('')
  }

  const handleCloseClickfunnelsDialog = () => {
    setShowClickfunnelsDialog(false)
    setAccountUrlClickfunnels('')
    setAccountNameClickfunnels('')
  }

  const handleCloseCommittedDialog = () => {
    setShowCommittedDialog(false)
    setAccountIdCommitted('')
    setAccountNameCommitted('')
  }

  const handleCloseCustomDialog = () => {
    setShowCustomDialog(false)
    setIdCustom('')
    setNameCustom('')
  }

  const handleCloseDigistore24Dialog = () => {
    setShowDigistore24Dialog(false)
    setAccountIdDigistore24('')
    setApiKeyDigistore24('')
  }

  const handleCloseEasyWebinarDialog = () => {
    setShowEasyWebinarDialog(false)
    setNicknameEasyWebinar('')
    setAccountUsernameEasyWebinar('')
  }

  const handleCloseHighlevelDialog = () => {
    setShowHighlevelDialog(false)
    setLocationIdHighlevel('')
    setBusinessNameHighlevel('')
  }

  const handleCloseKajabiDialog = () => {
    setShowKajabiDialog(false)
    setSiteIdKajabi('')
    setSiteNameKajabi('')
  }

  const handleCloseKitDialog = () => {
    setShowKitDialog(false)
    setApiKeyKit('')
    setAccountNameKit('')
    setAccountIdKit('')
  }

  const handleCloseKonnektiveDialog = () => {
    setShowKonnektiveDialog(false)
    setNicknameKonnektive('')
    setUsernameKonnektive('')
    setLoginIdKonnektive('')
    setPasswordKonnektive('')
  }

  const handleCloseMaropostDialog = () => {
    setShowMaropostDialog(false)
    setNicknameMaropost('')
    setAccountIdMaropost('')
  }

  const handleCloseOntraportDialog = () => {
    setShowOntraportDialog(false)
    setNicknameOntraport('')
    setAppIdOntraport('')
    setApiKeyOntraport('')
  }

  const handleClosePaypalDialog = () => {
    setShowPaypalDialog(false)
    setClientIdPaypal('')
    setSecretPaypal('')
  }

  const handleCloseSamCartDialog = () => {
    setShowSamCartDialog(false)
    setAccountIdSamCart('')
    setMarketplaceNameSamCart('')
    setApiKeySamCart('')
  }

  const handleCloseStickyDialog = () => {
    setShowStickyDialog(false)
    setAccountIdSticky('')
    setAccountNameSticky('')
    setApiUsernameSticky('')
    setApiPasswordSticky('')
  }

  const handleCloseUltracartDialog = () => {
    setShowUltracartDialog(false)
    setAccountNicknameUltracart('')
    setMerchantIdUltracart('')
    setApiKeyUltracart('')
  }

  const handleCloseWoocommerceDialog = () => {
    setShowWoocommerceDialog(false)
    setStoreUrlWoocommerce('')
    setStoreNameWoocommerce('')
    setConsumerKeyWoocommerce('')
    setConsumerSecretWoocommerce('')
  }

  const handleCloseElementorDialog = () => {
    setShowElementorDialog(false)
    setSiteUrlWordpress('')
    setSiteNameWordpress('')
  }

  const validateUrlFormat = (value) => {
    const re = /^((http|https):\/\/)(www.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/gm
    const match = value.match(re)
    return Boolean(match)
  }

  return (
    <Container maxWidth='md'>
      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Bask Health Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'baskhealth').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.BASKHEALTH_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='baskhealth_logo'
                        src={baskhealthLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box>
            <Button
              onClick={() => setShowBaskHealthDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showBaskHealthDialog}
            onClose={handleCloseBaskHealthDialog}
          >
            <DialogTitle>
              Connect with Bask Health
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect Bask Health with LTV Numbers using your Account ID and Name.
                <br />
                <br />
                The <b>Account ID</b> can be found by clicking on Settings in the left menu.
                In the top left, you should see your <em>Admin URL</em>, which will look like this: ACCOUNT_ID.mybaskhealth.com/admin.
                Enter your ACCOUNT_ID below.
                <br />
                <br />
                The <b>Store Name</b> can be found on the same page under Store details &gt; Store Name. Enter this value below.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Account ID'
                  name='baskhealth-account-id'
                  value={accountIdBaskHealth}
                  onChange={(event) => setAccountIdBaskHealth(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Store Name'
                  name='baskhealth-account-name'
                  value={accountNameBaskHealth}
                  onChange={(event) => setAccountNameBaskHealth(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseBaskHealthDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectBaskHealth()}
                color='secondary'
                disabled={!accountIdBaskHealth || !accountNameBaskHealth}
              >
                {isLoadingConnectBaskHealth ? <CircularProgress color='inherit' size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>
      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            BuyGoods Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'buygoods').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.BUYGOODS_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='buygoods_logo'
                        src={buygoodsLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowBuygoodsDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showBuygoodsDialog}
            onClose={handleCloseBuygoodsDialog}
          >
            <DialogTitle>
              Connect with BuyGoods
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect BuyGoods with LTV Numbers by providing account username and timezone.
                <br />
                <br />
                The <em>Account ID</em> is the ID of your Buygoods acccount. Where you will find this, will depend if you
                are logged in as a seller or affiliate.
                <br />
                <br />
                <b>Seller:</b> After logging in, it can be found in the URL
                here: https://admin.buygoods.com/ACCOUNT_ID. It will be a numeric value. Insert this value below exactly as it appears on the page.
                <br />
                <br />
                <b>Affiliate:</b> After logging in to the backoffice, navigate to the specific affiliate account you would like to connect.
                You will then find the Account ID in the URL here:
                https://backoffice.buygoods.com/?a=ACCOUNT_ID. It will be a numeric value after <em>?a</em>. This represents your affiliate ID.
                Insert this value below exactly as it appears on the page.
                <br />
                <br />
                The <em>Account Name</em> is the name of your Buygoods acccount. We recommend setting this to the name you see in the top bar
                when logged in, but technically this can be whatever you want to see in LTV Numbers for this source.
                <br />
                <br />
                To find the <em>timezone</em> in Buygoods, click the top right menu, choose Contact Info, and you will find the timezone below.
                Choose a matching timezone in the dropdown menu below. Note that this value can be changed later if necessary on the integration page.
                NOTE: if you are using your Buygoods affiliate account only, you will not find the timezone. In this case, the timezone is irrelevant, so
                just enter your local timezone below.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Account ID'
                  name='buygoods-account-ID'
                  value={accountIdBuygoods}
                  onChange={(event) => setAccountIdBuygoods(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Account Name'
                  name='buygoods-account-name'
                  value={accountNameBuygoods}
                  onChange={(event) => setAccountNameBuygoods(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2} display='flex' alignItems='center'>
                <FormControl
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  required
                  >
                  <Autocomplete
                    id='timezone'
                    options={moment.tz.names()}
                    size='small'
                    value={accountTimezoneBuygoods}
                    onChange={event => setAccountTimezoneBuygoods(event.target.textContent)}
                    renderInput={(params) => <TextField {...params} label='Timezone' required />}
                  />
                </FormControl>
              </Box>

            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseBuygoodsDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectBuygoods()}
                color='secondary'
                disabled={!accountIdBuygoods || !accountNameBuygoods || !accountTimezoneBuygoods}
              >
                {isLoadingConnectBuygoods ? <CircularProgress color='inherit' size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </Dialog>

        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            ClickBank Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'clickbank').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.CLICKBANK_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='clickbank_logo'
                        src={clickbankLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowClickbankDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showClickbankDialog}
            onClose={handleCloseClickbankDialog}
          >
            <DialogTitle>
              Connect with ClickBank
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect ClickBank with LTV Numbers by providing account nickname and INS Secret Key.
                <br />
                <br />
                The <em>account nickname</em> is the short account name you use to login to Clickbank. After logging in, it can be found at the top of the page.
                <br />
                <br />
                The <em>Secret</em> can be found by clicking &quot;Vendor Settings&quot;, scrolling down to &quot;Advanced Tools&quot;, then
                copying the &quot;Secret Key&quot; value that appears. If the value is blank, you can create one by clicking &quot;Edit&quot;
                in the top right of the Advanced Tools section. Enter a random sequence of up to 16 characters, including captial letters and numbers only.
                Click &quot;Save Changes&quot; when done, and insert the value below.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Account Nickname'
                  name='clickbank-account-nickname'
                  value={accountNicknameClickbank}
                  onChange={(event) => setAccountNicknameClickbank(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Secret Key'
                  name='clickbank-secret-key'
                  value={secretKeyClickbank}
                  onChange={(event) => setSecretKeyClickbank(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseClickbankDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectClickbank()}
                color='secondary'
                disabled={!accountNicknameClickbank || !secretKeyClickbank}
              >
                {isLoadingConnectClickbank ? <CircularProgress color='inherit' size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </Dialog>

        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Clickfunnels Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'clickfunnels').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.CLICKFUNNELS_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='clickfunnels_logo'
                        src={clickfunnelsLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowClickfunnelsDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showClickfunnelsDialog}
            onClose={handleCloseClickfunnelsDialog}
          >
            <DialogTitle>
              Connect with Clickfunnels
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect your Clickfunnels account to LTV Numbers for lead and purchase capture by providing your account info below.
                <br />
                <br />
                For the <b>Account URL</b>, login to your Clickfunnels account and copy the URL from your browser bar
                but only include the domain name. This will look like &quot;https://MYDOMAIN-app.clickfunnels.com&quot;.
                Enter this value below.
                <br />
                <br />
                For the <b>Account Name</b>, you can set this to how you want to identify the account. It is recommended that you use
                a unique, descriptive name that you will recognize in case you have multiple Clickfunnels accounts.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Account URL'
                  name='clickfunnels-account-url'
                  value={accountUrlClickfunnels}
                  onChange={(event) => setAccountUrlClickfunnels(event.target.value)}
                  variant='outlined'
                  size='small'
                  error={!validateUrlFormat(accountUrlClickfunnels)}
                  helperText={validateUrlFormat(accountUrlClickfunnels) ? '' : 'Invalid domain format. Please ensure URL is only the base domain of the site.'}
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Account Name'
                  name='clickfunnels-account-name'
                  value={accountNameClickfunnels}
                  onChange={(event) => setAccountNameClickfunnels(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseClickfunnelsDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectClickfunnels()}
                color='secondary'
                disabled={!accountUrlClickfunnels || !accountNameClickfunnels || !validateUrlFormat(accountUrlClickfunnels)}
              >
                {isLoadingConnectClickfunnels ? <CircularProgress color='inherit' size={24} /> : 'Connect'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Committed Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'committed').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.COMMITTED_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='committed_logo'
                        src={committedLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowCommittedDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showCommittedDialog}
            onClose={handleCloseCommittedDialog}
          >
            <DialogTitle>
              Connect with Committed
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect your Committed account to LTV Numbers for sales tracking by providing your account info below.
                <br />
                <br />
                For the <b>Account Name</b>, please use your company name as it relates to your work with Committed.
                <br />
                <br />
                For the <b>Account ID</b>, this value must be unique to you, so we recommend using your account name,
                but lowercase and with hyphens instead of spaces. Note that this value cannot be changed later.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Account Name'
                  name='committed-account-name'
                  value={accountNameCommitted}
                  onChange={(event) => setAccountNameCommitted(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Account ID'
                  name='committed-account-id'
                  value={accountIdCommitted}
                  onChange={(event) => setAccountIdCommitted(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseCommittedDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectCommitted()}
                color='secondary'
                disabled={!accountIdCommitted || !accountNameCommitted}
              >
                {isLoadingConnectCommitted ? <CircularProgress color='inherit' size={24} /> : 'Connect'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Digistore24 Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'digistore24').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.DIGISTORE24_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='digistore24_logo'
                        src={digistore24LogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowDigistore24Dialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showDigistore24Dialog}
            onClose={handleCloseDigistore24Dialog}
          >
            <DialogTitle>
              Connect with Digistore24
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect Digistore24 with LTV Numbers using an API key. Instructions for creating a Digistore24 API Key
                can be found <a href="https://dev.digistore24.com/en/articles/3-api-basics" target='_blank' rel='noreferrer'>here under the &quot;API Key&quot; header</a>.
                <br />
                <br />
                When following these instructions, name the application &quot;LTV Numbers&quot; and set the access level to &quot;Full access&quot;.
                Note that write access is only needed to automatically generate an IPN for realtime sales data. Your customer information will
                never be affected in any way. All other values can be left with default values. After saving the API Key, enter the
                generated value in the <em>API Key</em> field below.
                <br />
                <br />
                The <em>Digistore24 ID</em> is the ID you use to login to your online Digistore24 account. It can also be found at the
                top-left of the screen once you are logged in.
                <br />
                <br />
                <b>Affiliate Tracking:</b> If you are only setting up this account for affiliate tracking, you may enter <em>none</em> in
                the <b>API Key</b> field only if this Digistore ID is exclusively used for affiliate promos. If you plan to track
                seller data from this same Digistore ID, you must setup the API Key as described above and enter in below before proceeding.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='API Key'
                  name='digistore24-api-key'
                  value={apiKeyDigistore24}
                  onChange={(event) => setApiKeyDigistore24(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Digistore24 ID'
                  name='digistore24-account-id'
                  value={accountIdDigistore24}
                  onChange={(event) => setAccountIdDigistore24(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseDigistore24Dialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectDigistore24()}
                color='secondary'
                disabled={!accountIdDigistore24 || !apiKeyDigistore24}
              >
                {isLoadingConnectDigistore24 ? <CircularProgress color='inherit' size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </Dialog>

        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            DropFunnels Sources
          </Typography>
          <Typography variant='body1' align='left'>
            Webhook URL
          </Typography>
          <Box display='flex' flexDirection='row'>
            <InputBase
              variant='filled'
              value={webhookUrlDropfunnels}
              size='small'
              fullWidth
              disabled
            />
            <Tooltip
              open={showCopyTooltipDropfunnels}
              onClose={() => setShowCopyTooltipDropfunnels(false)}
              leaveDelay={2000}
              placement='right'
              title='Copied'
            >
              <Button
                variant='text'
                color='primary'
                onClick={() => copyToClipboardDropfunnels(webhookUrlDropfunnels)}
              >
                Copy
              </Button>
            </Tooltip>
          </Box>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowFunnelDialogDropfunnels(true)}
              variant='contained'
              color='primary'
            >
              Add Funnel
            </Button>
          </Box>
          <Dialog
            open={showFunnelDialogDropfunnels}
            onClose={handleCloseFunnelDialogDropfunnels}
          >
            <DialogTitle>
              Add Funnel
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect funnel to LTV Numbers by providing the funnel name and Funnel Browser URL.
                To get this information, open the funnel in DropFunnels and open the Settings.
                <br />
                <br />
                The funnel name can be found in the settings under <b>Funnel Name</b>.
                Get the funnel URL by copying the URL from the browser while on the settings page.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Funnel Name'
                  name='funnel-name-dropfunnels'
                  value={funnelNicknameDropfunnels}
                  onChange={(event) => setFunnelNicknameDropfunnels(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Funnel URL'
                  name='funnel-url-dropfunnels'
                  value={funnelUrlDropfunnels}
                  onChange={(event) => setFunnelUrlDropfunnels(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFunnelDialogDropfunnels}>
                Cancel
              </Button>
              <Button onClick={() => addFunnelDropfunnels()} color='secondary'>
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Easy Webinar Sources (via Zapier)
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'easywebinar').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.EASYWEBINAR_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='easywebinar_logo'
                        src={easywebinarLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowEasyWebinarDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showEasyWebinarDialog}
            onClose={handleCloseEasyWebinarDialog}
          >
            <DialogTitle>
              Connect with Easy Webinar
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect Easy Webinar with LTV Numbers using your Account ID and a nickname.
                <br />
                <br />
                The <b>Account Username</b> can be found by clicking on the menu in the top right corner, then going to <b>Profile</b>.
                Your username can be found under <b>Manage Profile</b>. Copy the value and enter it below.
                <br />
                <br />
                The <b>Nickname</b> should be a descriptive name that clearly identifies the Easy Webinar account you are integrating.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Account ID'
                  name='easywebinar-account-id'
                  value={accountUsernameEasyWebinar}
                  onChange={(event) => setAccountUsernameEasyWebinar(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Account Nickname'
                  name='easywebinar-nickname'
                  value={nicknameEasyWebinar}
                  onChange={(event) => setNicknameEasyWebinar(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseEasyWebinarDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectEasyWebinar()}
                color='secondary'
                disabled={!nicknameEasyWebinar || !accountUsernameEasyWebinar}
              >
                {isLoadingConnectEasyWebinar ? <CircularProgress color='inherit' size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            HighLevel Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'highlevel').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.HIGHLEVEL_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='highlevel_logo'
                        src={highlevelLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowHighlevelDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showHighlevelDialog}
            onClose={handleCloseHighlevelDialog}
          >
            <DialogTitle>
              Connect with HighLevel
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect your HighLevel account to LTV Numbers for lead and purchase capture by providing your account info below.
                <br />
                <br />
                For the <b>Location ID</b>, login to your HighLevel account, select &quot;Settings&quot; from the left menu bar,
                then go to &quot;Business Profile&quot;. Near the top, you should see &quot;Location ID&quot;. Copy the value
                and enter it below.
                <br />
                <br />
                For the <b>Business Name</b>, use the &quot;Friendly Business Name&quot; from the same profile page and enter it below.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Location ID'
                  name='highlevel-location-id'
                  value={locationIdHighlevel}
                  onChange={(event) => setLocationIdHighlevel(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Business Name'
                  name='highlevel-business-name'
                  value={businessNameHighlevel}
                  onChange={(event) => setBusinessNameHighlevel(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseHighlevelDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectHighlevel()}
                color='secondary'
                disabled={!locationIdHighlevel || !businessNameHighlevel}
              >
                {isLoadingConnectHighlevel ? <CircularProgress color='inherit' size={24} /> : 'Connect'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Kajabi Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'kajabi').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.KAJABI_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='kajabi_logo'
                        src={kajabiLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowKajabiDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showKajabiDialog}
            onClose={handleCloseKajabiDialog}
          >
            <DialogTitle>
              Connect with Kajabi
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect your Kajabi account to LTV Numbers for sales data capture by providing your account details below.
                <br />
                <br />
                For the <b>Site ID</b>, login to your Kajabi account, and look at the URL in your browser. It should look something like <em>https://app.kajabi.com/admin/sites/12345/</em>.
                <br />
                <br />
                The number between slashes after &quot;sites&quot; is your <b>Site ID</b>. Please enter that value below.
                <br />
                <br />
                For the <b>Site Name</b>, please enter the name shown in the top left corner of your Kajabi dashboard.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Site ID'
                  name='kajabi-site-id'
                  value={siteIdKajabi}
                  onChange={(event) => setSiteIdKajabi(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Site Name'
                  name='kajabi-site-name'
                  value={siteNameKajabi}
                  onChange={(event) => setSiteNameKajabi(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseKajabiDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectKajabi()}
                color='secondary'
                disabled={!siteIdKajabi || !siteNameKajabi}
              >
                {isLoadingConnectKajabi ? <CircularProgress color='inherit' size={24} /> : 'Connect'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper>
          <Box padding={2}>
            <Typography variant='h5' align='center' gutterBottom>
              Keap Sources
            </Typography>

            <List>
              {isLoadingIntegrations ?
                <Skeleton variant='rectangular' height={35} />
                : (sortedIntegrations.filter(int => int.platform === 'keap').map(integration =>
                  <Fragment key={integration.id}>
                    <ListItem
                      onClick={() => history.push(ROUTES.KEAP_SOURCES + `/${integration.id}`)}
                      button
                    >
                      <ListItemIcon>
                        <img
                          className={classes.logoImg}
                          alt='keap_logo'
                          src={keapLogoImg}
                        />
                      </ListItemIcon>
                      <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                    </ListItem>
                  </Fragment>
                ))
              }
            </List>

            <a href={`https://accounts.infusionsoft.com/app/oauth/authorize?client_id=${KEAP_CLIENT_ID}&redirect_uri=${SITE_ROOT_URL + ROUTES.KEAP_OAUTH}&response_type=code&scope=full`}>
              <Button
                variant='contained'
                color='primary'
                endIcon={<OpenInNewIcon />}
              >
                Connect
              </Button>
            </a>
          </Box>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Kit Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'kit').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.KIT_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='kit_logo'
                        src={kitLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowKitDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showKitDialog}
            onClose={handleCloseKitDialog}
          >
            <DialogTitle>
              Connect with Kit
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect Kit to LTV Numbers using a v4 API Key.
                <br />
                <br />
                To create a v4 API Key in Kit, follow the <a href='https://help.kit.com/en/articles/9902901-creating-api-keys' target='_blank' rel='noreferrer'>instructions here</a> and
                name the key &quot;LTV Numbers&quot;. Copy the generated key and enter it for the <b>API Key</b> below.
                <br />
                <br />
                For the <b>Account Name</b> field, enter the name you in the top right corner when logged in to Kit. This is your Business Name.
                <br />
                <br />
                For <b>Account ID</b> field, this can be whatever you want to uniquely identify this account. We recommend your business name
                with hypens instead of spaces, such as <em>my-business-name</em>.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='API Key'
                  name='kit-api-key'
                  value={apiKeyKit}
                  onChange={(event) => setApiKeyKit(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Account Name'
                  name='kit-account-name'
                  value={accountNameKit}
                  onChange={(event) => setAccountNameKit(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Account ID'
                  name='kit-account-id'
                  value={accountIdKit}
                  onChange={(event) => setAccountIdKit(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseKitDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectKit()}
                color='secondary'
                disabled={!apiKeyKit || !accountNameKit || !accountIdKit }
              >
                {isLoadingConnectKit ? <CircularProgress color='inherit' size={24} /> : 'Connect'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Konnektive/Checkout Champ Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'konnektive').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.KONNEKTIVE_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='konnektive_logo'
                        src={konnektiveLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowKonnektiveDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showKonnektiveDialog}
            onClose={handleCloseKonnektiveDialog}
          >
            <DialogTitle>
              Connect with Konnektive
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect Konnektive account to LTV Numbers by setting up an API User and providing the necessary information below. If you are planning to use webhooks and not the API, jump to the bottom of these instructions.
                <br />
                <br />
                To create an API User, follow the <a href='https://konnektive.atlassian.net/wiki/spaces/KCKB/pages/222986302/Create+An+API+User' target='_blank' rel='noreferrer'>instructions here</a> and
                note the additional instructions below as you complete the steps.
                <br />
                <br />
                For the <b>Username</b> field, enter &quot;ltvnumbers-api-UNIQUE_ID&quot;, where UNIQUE_ID is replaced by something unique to you or your business, such as an abbreviation of your company name.
                This value must also be put into the <b>API Username</b> field below.
                The <b>Email Address</b> can be your own email address. The <b>Access Level</b> must be set to &quot;Api User&quot;.
                For <b>IP Whitelist</b>, enter &quot;GOOGLE&quot;.
                <br />
                <br />
                For <b>Password</b> create a secure password of your choice and enter it. This value must also be put into the <b>API Password</b> field below.
                Note that <b>the password cannot contain an &quot;&&quot; character.</b>
                <br />
                <br />
                For <b>Access Details</b>, under Allowed API Endpoints, check the boxes next to: Query Orders, Query Customers, Query Transactions, Query Purchase, Query Campaign, Query Product, Query Affiliates.
                <br />
                <br />
                In Konnektive, be sure to click &quot;Create&quot; to create the user before submitting this information to LTV Numbers.
                <br />
                <br />
                Below, you will also need to enter your Konnektive account name, shown at the top right of your Konnektive dashboard in the <b>Account Name</b> field below. Then enter your Konnektive login username in the <b>Account Username</b> field below.
                <br />
                <br />
                WEBHOOKS ONLY: If you are planning to use webhooks instead of the API, enter the following placeholder value below for both the <b>API Username</b> and <b>API Password</b>: &quot;ltv_webhook_placeholder&quot;. Then follow the instructions on the previous paragraph for the <b>Account Name</b> and <b>Account Username</b>.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='API Username'
                  name='konnektive-api-username'
                  value={loginIdKonnektive}
                  onChange={(event) => setLoginIdKonnektive(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='API Password'
                  name='konnektive-password'
                  value={passwordKonnektive}
                  onChange={(event) => setPasswordKonnektive(event.target.value)}
                  variant='outlined'
                  size='small'
                  error={passwordKonnektive.indexOf('&') > -1}
                  helperText={passwordKonnektive.indexOf('&') > -1 ? 'Password cannot contain a "&" character' : ''}
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Account Name'
                  name='konnektive-account-name'
                  value={nicknameKonnektive}
                  onChange={(event) => setNicknameKonnektive(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Account Username'
                  name='konnektive-username'
                  value={usernameKonnektive}
                  onChange={(event) => setUsernameKonnektive(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseKonnektiveDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectKonnektive()}
                color='secondary'
                disabled={!nicknameKonnektive || !usernameKonnektive || !loginIdKonnektive || !passwordKonnektive || passwordKonnektive.indexOf('&') > -1}
              >
                {isLoadingConnectKonnektive ? <CircularProgress color='inherit' size={24} /> : 'Connect'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Maropost Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'maropost').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.MAROPOST_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='maropost_logo'
                        src={maropostLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowMaropostDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showMaropostDialog}
            onClose={handleCloseMaropostDialog}
          >
            <DialogTitle>
              Connect with Maropost
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect Maropost with LTV Numbers using your Account ID and an nickname.
                <br />
                <br />
                The <b>Account ID</b> can be found inside Maropost by clicking on the name in the top right corner,
                selecting <b>Account & Billing</b>, then clicking on the <b>Company Information</b> tab.
                Here you will find the <b>Account ID,</b> which you can enter below.
                <br />
                <br />
                The <b>Nickname</b> should be a descriptive name that clearly identifies the Maropost account you are integrating.
                The account name found in the top right corner is a good option, but you are free to call it whatever you want.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Account ID'
                  name='maropost-account-id'
                  value={accountIdMaropost}
                  onChange={(event) => setAccountIdMaropost(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Account Nickname'
                  name='maropost-nickname'
                  value={nicknameMaropost}
                  onChange={(event) => setNicknameMaropost(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseMaropostDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectMaropost()}
                color='secondary'
                disabled={!nicknameMaropost || !accountIdMaropost}
              >
                {isLoadingConnectMaropost ? <CircularProgress color='inherit' size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Ontraport Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'ontraport').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.ONTRAPORT_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='ontraport_logo'
                        src={ontraportLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowOntraportDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showOntraportDialog}
            onClose={handleCloseOntraportDialog}
          >
            <DialogTitle>
              Connect with Ontraport
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect Ontraport with LTV Numbers using an the Ontraport API. Create an Ontraport App ID and API key by following the
                instructions <a href="https://ontraport.com/support/integrations/obtain-ontraport-api-key-and-app-id/" target='_blank' rel='noreferrer'>here</a>.
                <br />
                <br />
                When following these instructions, name the API Key &quot;LTV Numbers&quot; and check the permission for &quot;Search Contacts&quot;.
                After saving the API Key, click on it in the list of keys, then enter the values for <b>API App ID</b> and <b>API Key</b> in the corresponding fields below.
                <br />
                <br />
                The <b>Nickname</b> should be a descriptive name that clearly identifies the Ontraport account you are integrating.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Account Nickname'
                  name='ontraport-nickname'
                  value={nicknameOntraport}
                  onChange={(event) => setNicknameOntraport(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='API App ID'
                  name='ontraport-api-app-id'
                  value={appIdOntraport}
                  onChange={(event) => setAppIdOntraport(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='API Key'
                  name='ontraport-api-key'
                  value={apiKeyOntraport}
                  onChange={(event) => setApiKeyOntraport(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseOntraportDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectOntraport()}
                color='secondary'
                disabled={!nicknameOntraport || !appIdOntraport || !apiKeyOntraport}
              >
                {isLoadingConnectOntraport ? <CircularProgress color='inherit' size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </Dialog>

        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            PayPal Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'paypal').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.PAYPAL_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='paypal_logo'
                        src={paypalLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowPaypalDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showPaypalDialog}
            onClose={handleClosePaypalDialog}
          >
            <DialogTitle>
              Connect with PayPal
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect PayPal account to LTV Numbers by providing your application Client ID and Secret.
                To get this information, open the <a href='https://developer.paypal.com/developer/applications' target='_blank' rel='noreferrer'>PayPal Developer Dashboard</a> and
                select the live mode application you would like to connect.
                <br />
                <br />
                The <em>Client ID</em> is the long sequence of letters and numbers found under <b>Client ID</b>.
                <br />
                <br />
                The <em>Secret</em> can be found by clicking &quot;Show&quot; under <b>Secret</b> and
                copying the &quot;Secret&quot; value that appears.
                <br />
                <br />
                Before connecting, go to <b>LIVE APP Settings</b> on the PayPal app page and check all boxes under <b>App feature options</b>. This
                ensures the integration will have access to the information needed to load account data.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Client ID'
                  name='paypal-client-id'
                  value={clientIdPaypal}
                  onChange={(event) => setClientIdPaypal(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Secret'
                  name='paypal-secret'
                  value={secretPaypal}
                  onChange={(event) => setSecretPaypal(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClosePaypalDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectPaypal()}
                color='secondary'
                disabled={!clientIdPaypal || !secretPaypal}
              >
                {isLoadingConnectPaypal ? <CircularProgress color='inherit' size={24} /> : 'Connect'}
              </Button>
            </DialogActions>
          </Dialog>

        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            SamCart Sources
          </Typography>

          <Typography variant='h6' align='left'>
            Webhook URL for legacy accounts
          </Typography>

          <Box display='flex' flexDirection='row'>
            <InputBase
              variant='filled'
              value={webhookUrlSamCart}
              size='small'
              fullWidth
              disabled
            />
            <Tooltip
              open={showCopyTooltip}
              onClose={() => setShowCopyTooltip(false)}
              leaveDelay={2000}
              placement='right'
              title='Copied'
            >
              <Button
                variant='text'
                color='primary'
                onClick={() => copyToClipboard(webhookUrlSamCart)}
              >
                Copy
              </Button>
            </Tooltip>
          </Box>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'samcart').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.SAMCART_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='samcart_logo'
                        src={samcartLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowSamCartDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showSamCartDialog}
            onClose={handleCloseSamCartDialog}
          >
            <DialogTitle>
              Connect with SamCart
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect SamCart with LTV Numbers using an API key. At the moment, the SamCart API is in a closed beta and requires
                a personal request to generate an API Key.
                <br />
                <br />
                The <em>Account ID</em> is the ID seen in the browser URL when logged in that comes that looks like &quot;app.samcart.com/marketplace/ACCOUNT_ID&quot;.
                <br />
                <br />
                The <em>Marketplace Name</em> can be found by clicking on Settings, then looking for GENERAL MARKETPLACE SETTINGS &gt; Marketplace Name.
                <br />
                <br />
                To use the SamCart API, you must be on their highest plan. As an alternative, you can send an email to support@samcart.com with subject &quot;API Key Request&quot;.
                In the email, mention you are working with LTV Numbers, and would like to generate an API Key for your account. This may or may not work,
                but if it does, someone will get back to you via email with an One-time API Access Key. Use the Key as the value for <em>API Key</em> below.
                <br />
                <br />
                Note that using the SamCart API is not at all necessary to sync your SamCart data, and the same level of analysis can be accomplished
                using data exports from SamCart, as detailed in the next step.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Account ID'
                  name='samcart-account-id'
                  value={accountIdSamCart}
                  onChange={(event) => setAccountIdSamCart(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Marketplace Name'
                  name='samcart-marketplace-name'
                  value={marketplaceNameSamCart}
                  onChange={(event) => setMarketplaceNameSamCart(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='API Key (not required)'
                  name='samcart-api-key'
                  value={apiKeySamCart}
                  onChange={(event) => setApiKeySamCart(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseSamCartDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectSamCart()}
                color='secondary'
                disabled={!accountIdSamCart || !marketplaceNameSamCart }
              >
                {isLoadingConnectSamCart ? <CircularProgress color='inherit' size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </Dialog>

        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper>
          <Box padding={2}>
            <Typography variant='h5' align='center' gutterBottom>
              Shopify Sources
            </Typography>

            <List>
              {isLoadingIntegrations ?
                <Skeleton variant='rectangular' height={35} />
                : (sortedIntegrations.filter(int => int.platform === 'shopify').map(integration =>
                  <Fragment key={integration.id}>
                    <ListItem
                      onClick={() => history.push(ROUTES.SHOPIFY_SOURCES + `/${integration.id}`)}
                      button
                    >
                      <ListItemIcon>
                        <img
                          className={classes.logoImg}
                          alt='shopify_logo'
                          src={shopifyLogoImg}
                        />
                      </ListItemIcon>
                      <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                    </ListItem>
                  </Fragment>
                ))
              }
            </List>

            <a href={`https://apps.shopify.com/ltv-numbers`} target='_blank' rel='noreferrer'>
              <Button
                variant='contained'
                color='primary'
                endIcon={<OpenInNewIcon />}
              >
                Install Shopify App
              </Button>
            </a>
          </Box>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Sticky.io Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'sticky').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.STICKY_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='sticky_logo'
                        src={stickyLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowStickyDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showStickyDialog}
            onClose={handleCloseStickyDialog}
          >
            <DialogTitle>
              Connect with Sticky
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect Sticky account to LTV Numbers by setting up an API User and providing the necessary information below.
                <br />
                <br />
                To ensure this integration works as expected, <b>login to Sticky using the user account that the Sticky account was originally created with.</b>
                <br />
                <br />
                To create an API User, follow the <a href='https://support.sticky.io/en/articles/3773907-api-accounts#' target='_blank' rel='noreferrer'>instructions here</a> and
                note the additional instructions below as you complete the steps.
                <br />
                <br />
                For the <b>Username</b> field, enter &quot;ltvnumbers-api&quot;, or another similar identifier. This value must be put into the <b>API Username</b> field below.
                The <b>Email Address</b> can be an email address of your choice.
                Fill out the rest of the info as you see fit, then press &quot;Save&quot; to create the user.
                <br />
                <br />
                A password will be automatically generated and you will see the value in the API Accounts table. Copy this password
                value and enter it in the <b>API Password</b> field below.
                <br />
                <br />
                You must give this API Account specific permissions for LTV Numbers to access the required data in your account.
                To do this, locate the API Account you just created, and on the right side of the table you will see a &quot;Permissions&quot; button that looks like three gears.
                Click this button, and on the Permissions page, enable <em>campaign_view</em>, <em>order_find</em>, and <em>order_view</em>. Scroll to the bottom and save the permissions.
                <br />
                <br />
                For the <b>Account Name</b>, enter a descriptive name of your choice that identifies this Sticky account.
                <br />
                <br />
                Below, you will also need to enter your Sticky account ID, which is in the browser URL when you are logged in with a format of &quot;ACCOUNTID.sticky.io&quot;.
                Enter this value in the <b>Account ID</b> field below. It is very important that you enter the ACCOUNTID value exactly as it appears in the URL, or the API Account will not work.
                <br />
                <br />
                For the <b>Timezone</b>, use the top navigation menu to go to Settings &gt; ACCOUNTS & PERMISSIONS. Find the user that
                you are logged in with, and click the edit button on the right side of the table that looks like a pencil.
                On the edit page, find &quot;Time Zone&quot; value and find a matching value in the <b>Timezone</b> dropdown below.
                Note that this value can be changed later if necessary on the integration page, but it is strongly recommended
                that you never change the value once set, or it can cause inconsistencies in the data.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='API Username'
                  name='sticky-api-username'
                  value={apiUsernameSticky}
                  onChange={(event) => setApiUsernameSticky(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='API Password'
                  name='sticky-api-password'
                  value={apiPasswordSticky}
                  onChange={(event) => setApiPasswordSticky(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Account Name'
                  name='sticky-account-name'
                  value={accountNameSticky}
                  onChange={(event) => setAccountNameSticky(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Account ID'
                  name='sticky-account-id'
                  value={accountIdSticky}
                  onChange={(event) => setAccountIdSticky(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2} display='flex' alignItems='center'>
                <FormControl
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  required
                  >
                  <Autocomplete
                    id='timezone'
                    options={moment.tz.names()}
                    size='small'
                    value={accountTimezoneSticky}
                    onChange={event => setAccountTimezoneSticky(event.target.textContent)}
                    renderInput={(params) => <TextField {...params} label='Timezone' required />}
                  />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseStickyDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectSticky()}
                color='secondary'
                disabled={!apiUsernameSticky || !apiPasswordSticky || !accountNameSticky || !accountIdSticky }
              >
                {isLoadingConnectSticky ? <CircularProgress color='inherit' size={24} /> : 'Connect'}
              </Button>
            </DialogActions>
          </Dialog>

        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Stripe Sources
          </Typography>

            <List>
              {isLoadingIntegrations ?
                <Skeleton variant='rectangular' height={35} />
                : (sortedIntegrations.filter(int => int.platform === 'stripe').map(integration =>
                  <Fragment key={integration.id}>
                    <ListItem
                      onClick={() => history.push(ROUTES.STRIPE_SOURCES + `/${integration.id}`)}
                      button
                    >
                      <ListItemIcon>
                        <img
                          className={classes.logoImg}
                          alt='stripe_logo'
                          src={stripeLogoImg}
                        />
                      </ListItemIcon>
                      <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                    </ListItem>
                  </Fragment>
                ))
              }
            </List>


          <a href={`https://connect.stripe.com/oauth/authorize?client_id=${STRIPE_CLIENT_ID}&response_type=code&redirect_uri=${STRIPE_REDIRECT_URI}&scope=read_only&stripe_landing=login&always_prompt=true`}>
            <img alt='connect_with_stripe' src={connectWithStripeImg}></img>
          </a>

        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            UltraCart Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'ultracart').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.ULTRACART_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='ultracart_logo'
                        src={ultracartLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowUltracartDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showUltracartDialog}
            onClose={handleCloseUltracartDialog}
          >
            <DialogTitle>
              Connect with UltraCart
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect your UltraCart account to LTV Numbers by generating an API key and providing the credentials. To do this, follow
                the <a href='https://ultracart.atlassian.net/wiki/spaces/ucdoc/pages/38688545/API+Simple+Key' target='_blank' rel='noreferrer'>instructions here</a> and
                the important notes below.
                <br />
                <br />
                When creating the key, set the &quot;Application Name&quot; to &quot;LTV Numbers&quot; (or a similar descriptive name).
                Ensure that &quot;Authentication Type&quot;, is set to &quot;Simple Key&quot;. For the &quot;Permissions&quot;, select <b>Read</b> for
                all options except &quot;User&quot;. Select <b>Write</b> for only the &quot;Webhooks&quot; option. <em>Note: the write permission is only used to create a webhook for realtime data syncing</em>.
                <br />
                <br />
                Once the API key is created, enter the <b>Simple Key</b> value below in the <b>Simple API Key</b> field.
                <br />
                <br />
                For the <b>Account Name</b>, enter a descriptive name of your choice that clearly identifies this UltraCart account.
                <br />
                <br />
                For the <b>Merchant ID</b>, you can find this by logging into your account and hovering on your profile image in the top left to display
                your <b>Merchant ID</b>. Enter this value below exactly as it appears.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Account Name'
                  name='ultracart-account-nickname'
                  value={accountNicknameUltracart}
                  onChange={(event) => setAccountNicknameUltracart(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Merchant ID'
                  name='ultracart-merchant-id'
                  value={merchantIdUltracart}
                  onChange={(event) => setMerchantIdUltracart(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Simple API Key'
                  name='ultracart-simple-apikey'
                  value={apiKeyUltracart}
                  onChange={(event) => setApiKeyUltracart(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseUltracartDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectUltracart()}
                color='secondary'
                disabled={!accountNicknameUltracart || !merchantIdUltracart || !apiKeyUltracart}
              >
                {isLoadingConnectUltracart ? <CircularProgress color='inherit' size={24} /> : 'Connect'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            WooCommerce Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'woocommerce').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.WOOCOMMERCE_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='woocommerce_logo'
                        src={woocommerceLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowWoocommerceDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showWoocommerceDialog}
            onClose={handleCloseWoocommerceDialog}
          >
            <DialogTitle>
              Connect with WooCommerce
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect your WooCommerce account to LTV Numbers by generating an API key and providing the credentials. To do this, follow
                the <a href='https://woocommerce.com/document/woocommerce-rest-api/' target='_blank' rel='noreferrer'>instructions here</a>.
                <br />
                <br />
                When creating the key, set the &quot;Description&quot; to &quot;LTV Numbers&quot; (or a similar descriptive name).
                For the &quot;User&quot;, select yourself. For &quot;Permissions&quot;, select &quot;Read/Write&quot;. <em>Note:
                the write permission is only used to create a webhook for realtime sales</em>.
                <br />
                <br />
                Once the API key is created, enter the <b>Consumer Key</b> and <b>Consumer Secret</b> values below. Note that you can only
                access these values immediately after creating the key. If you leave the page, they will not be available and you will
                need to create a new key.
                <br />
                <br />
                For the <b>Store URL</b>, enter the domain of your store. To get this, get the URL of the page when logged into WooCommerce,
                but only include the domain name. This will look like &quot;https://mystore.com&quot; or &quot;https://www.mystore.net&quot;.
                Enter this value below.
                <br />
                <br />
                For the <b>Store Name</b>, you can set this to how you want to identify the store, but it is recommended that you use the
                name of the store that shows up when logged into WooCommerce in the &quot;Home&quot; section.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Store URL'
                  name='woocommerce-store-url'
                  value={storeUrlWoocommerce}
                  onChange={(event) => setStoreUrlWoocommerce(event.target.value)}
                  variant='outlined'
                  size='small'
                  error={!validateUrlFormat(storeUrlWoocommerce)}
                  helperText={validateUrlFormat(storeUrlWoocommerce) ? '' : 'Invalid domain format. Please ensure URL is only the base domain of the store.'}
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Store Name'
                  name='woocommerce-store-name'
                  value={storeNameWoocommerce}
                  onChange={(event) => setStoreNameWoocommerce(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Consumer Key'
                  name='woocommerce-consumer-key'
                  value={consumerKeyWoocommerce}
                  onChange={(event) => setConsumerKeyWoocommerce(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Consumer Secret'
                  name='woocommerce-consumer-secret'
                  value={consumerSecretWoocommerce}
                  onChange={(event) => setConsumerSecretWoocommerce(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseWoocommerceDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectWoocommerce()}
                color='secondary'
                disabled={!storeUrlWoocommerce || !storeNameWoocommerce || !consumerKeyWoocommerce || !consumerSecretWoocommerce || !validateUrlFormat(storeUrlWoocommerce)}
              >
                {isLoadingConnectWoocommerce ? <CircularProgress color='inherit' size={24} /> : 'Connect'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            WordPress Elementor Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'wordpress').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.ELEMENTOR_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        className={classes.logoImg}
                        alt='elementor_logo'
                        src={elementorLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowElementorDialog(true)}
              variant='contained'
              color='primary'
            >
              Connect
            </Button>
          </Box>
          <Dialog
            open={showElementorDialog}
            onClose={handleCloseElementorDialog}
          >
            <DialogTitle>
              Connect with Elementor
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Connect your Elementor account to LTV Numbers for lead capture by providing your site info below.
                <br />
                <br />
                For the <b>Site URL</b>, enter the domain of your site. To get this, get the URL of the page when logged into WordPress,
                but only include the domain name. This will look like &quot;https://mysite.com&quot; or &quot;https://www.mysite.net&quot;.
                Enter this value below.
                <br />
                <br />
                For the <b>Site Name</b>, you can set this to how you want to identify the site, but it is recommended that you use the
                name of the site that shows up when logged into WordPress in the &quot;Home&quot; section.
                <br />
                <br />
                For the <b>Timezone</b>, go the WordPress dashboard and navigate to Settings &lt; General. Scroll to <em>Timezone</em>
                to see the value for your account. Find a matching value in the dropdown below. Note that this value can be changed later
                if necessary on the integration page.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Site URL'
                  name='wordpress-store-url'
                  value={siteUrlWordpress}
                  onChange={(event) => setSiteUrlWordpress(event.target.value)}
                  variant='outlined'
                  size='small'
                  error={!validateUrlFormat(siteUrlWordpress)}
                  helperText={validateUrlFormat(siteUrlWordpress) ? '' : 'Invalid domain format. Please ensure URL is only the base domain of the site.'}
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Site Name'
                  name='wordpress-site-name'
                  value={siteNameWordpress}
                  onChange={(event) => setSiteNameWordpress(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2} display='flex' alignItems='center'>
                <FormControl
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  required
                  >
                  <Autocomplete
                    id='timezone'
                    options={moment.tz.names()}
                    size='small'
                    value={siteTimezoneWordpress}
                    onChange={event => setSiteTimezoneWordpress(event.target.textContent)}
                    renderInput={(params) => <TextField {...params} label='Timezone' required />}
                  />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseElementorDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectElementor()}
                color='secondary'
                disabled={!siteUrlWordpress || !siteNameWordpress || !siteTimezoneWordpress || !validateUrlFormat(siteUrlWordpress)}
              >
                {isLoadingConnectElementor ? <CircularProgress color='inherit' size={24} /> : 'Connect'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Bucket.io 2.0 (via Zapier)
          </Typography>
          <Typography variant='body1' align='left'>
            Webhook URL
          </Typography>
          <Box display='flex' flexDirection='row'>
            <InputBase
              variant='filled'
              value={webhookUrlBucketZapier}
              size='small'
              fullWidth
              disabled
            />
            <Tooltip
              open={showCopyTooltip}
              onClose={() => setShowCopyTooltip(false)}
              leaveDelay={2000}
              placement='right'
              title='Copied'
            >
              <Button
                variant='text'
                color='primary'
                onClick={() => copyToClipboard(webhookUrlBucketZapier)}
              >
                Copy
              </Button>
            </Tooltip>
          </Box>
        </Paper>
      </Box>

      <Box marginTop={2}>
        <Paper className={classes.paper}>
          <Typography variant='h5' align='center' gutterBottom>
            Custom Sources
          </Typography>

          <List>
            {isLoadingIntegrations ?
              <Skeleton variant='rectangular' height={35} />
              : (sortedIntegrations.filter(int => int.platform === 'custom').map(integration =>
                <Fragment key={integration.id}>
                  <ListItem
                    onClick={() => history.push(ROUTES.CUSTOM_SOURCES + `/${integration.id}`)}
                    button
                  >
                    <ListItemIcon>
                      <img
                        style={{ width: 40 }}
                        alt='ltv_logo'
                        src={ltvLogoImg}
                      />
                    </ListItemIcon>
                    <ListItemText primary={integration.nickname} secondary={integration.platform_id} />
                  </ListItem>
                </Fragment>
              ))
            }
          </List>

          <Box marginTop={2}>
            <Button
              onClick={() => setShowCustomDialog(true)}
              variant='contained'
              color='primary'
            >
              Create Custom Source
            </Button>
          </Box>
          <Dialog
            open={showCustomDialog}
            onClose={handleCloseCustomDialog}
          >
            <DialogTitle>
              Create a Custom Source
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Create a custom integration to load data from any source into LTV Numbers.
                <br />
                <br />
                For the <b>Source ID</b>, enter a unique identifier for this integration.
                This value can not be changed and is used for internal identification.
                <br />
                <br />
                For the <b>Name</b>, you can set this to how you want to identify the account. It is recommended that you use
                a unique, descriptive name that you will recognize in case you have multiple custom integrations that you need to differentiate.
              </DialogContentText>
              <Box marginTop={2}>
                <TextField
                  label='Source ID'
                  name='custom-id'
                  value={idCustom}
                  onChange={(event) => setIdCustom(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
              <Box marginTop={2}>
                <TextField
                  label='Name'
                  name='custom-name'
                  value={nameCustom}
                  onChange={(event) => setNameCustom(event.target.value)}
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseCustomDialog}
                color='error'
              >
                Cancel
              </Button>
              <Button
                onClick={() => connectCustom()}
                color='secondary'
                disabled={!idCustom || !nameCustom}
              >
                {isLoadingConnectCustom ? <CircularProgress color='inherit' size={24} /> : 'Create'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={5000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default DataSources