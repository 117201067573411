import React from 'react'
import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import InputBase from '@mui/material/InputBase'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'

import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { API_ROOT_URL } from '../../constants'
import * as ROUTES from '../../constants/routes'

import stickyLogoImg from '../../media/sticky_logo.png'

const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
  logoImg: {
    width: 100,
  },
  syncButton: {
    width: 110,
  },
  redButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
}))

const REVOKE_SUCCESS_MESSAGE = 'Account removed. Data will no longer be synced from this account. Redirecting back to settings...'
const SYNC_SUCCESS_MESSAGE = 'Sync initialized. Processing may take a while, and you can safely leave this page.'
const SYNC_ERROR_MESSAGE = 'Sync failed to initialize. Try again and contact support if error persists.'
const WEBHOOK_URL_BASE = `https://us-central1-ltv-numbers-${process.env.REACT_APP_ENV === 'production' ? 'prod' : 'dev'}.cloudfunctions.net/webhooks/sticky/`

const dateRangeMenuItems = [
  { value: 'last_7_days', display: 'Last 7 days'},
  { value: 'last_30_days', display: 'Last 30 days'},
  { value: 'last_3_months', display: 'Last 3 months'},
  { value: 'last_6_months', display: 'Last 6 months'},
  { value: 'last_12_months', display: 'Last 12 months'},
  { value: 'last_2_years', display: 'Last 2 years'},
  { value: 'last_3_years', display: 'Last 3 years'},
  { value: 'ytd', display: 'Year to date'},
  { value: 'last_and_this_year', display: 'Last year and this year'},
  { value: 'all_time', display: 'All time'},
]

const StickySource = () => {
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const firebase = React.useContext(FirebaseContext)

  const [webhookUrl, setWebhookUrl] = React.useState('')
  const [showCopyTooltip, setShowCopyTooltip] = React.useState(false)

  const [timezone, setTimezone] = React.useState(TIMEZONE)
  const [message, setMessage] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [dateRange, setDateRange] = React.useState('last_2_years')
  const [messageSeverity, setMessageSeverity] = React.useState('success')
  const [showRevokeConfirmation, setShowRevokeConfirmation] = React.useState(false)

  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')

  React.useEffect(() => {
    setDatesFromRange(dateRange)
  }, [dateRange])

  React.useEffect(() => {
    const uid = firebase.auth().currentUser.uid
    setWebhookUrl(WEBHOOK_URL_BASE + uid + '/integration/' + id)
  }, [])

  const {
    isLoading: isLoadingIntegration,
    data: integration = {}
  } = useQuery(['integration-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.timezone) {
          setTimezone(data.timezone)
        }
      }
    }
  )

  const { isLoading: isUpdatingTimezone, mutate: updateTimezone } = useMutation(
    newTimezone => firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/sticky/sync`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id,
          timezone: newTimezone
        }),
      }).then(res => res.json())
    }),
    {
      onSuccess: () => {
        setShowMessage(true)
        setMessage('Timezone successfully updated')
        setMessageSeverity('success')
      },
      onError: () => {
        setShowMessage(true)
        setMessage('Error updating timezone. Please try again, and if problem persists, contact support.')
        setMessageSeverity('error')
      }
    }
  )

  const { isFetching: isSyncing, refetch: sync } = useQuery(['integration-sync', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/sticky/sync`, {
        method: 'POST',
        body: JSON.stringify({
          integration_id: id,
          startDate,
          endDate,
          timezone
        }),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      }).then(res => res.json())
    }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
      onSuccess: () => {
        setShowMessage(true)
        setMessage(SYNC_SUCCESS_MESSAGE)
        setMessageSeverity('success')
      },
      onError: () => {
        setShowMessage(true)
        setMessage(SYNC_ERROR_MESSAGE)
        setMessageSeverity('error')
      }
    }
  )


  const { isLoading: isLoadingRevoke, refetch: revoke } = useQuery(['revoke-sticky'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations/sticky/revoke`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          integration_id: id
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnReconnect: false,
      onSuccess: () => {
        setShowRevokeConfirmation(false)
        setMessage(REVOKE_SUCCESS_MESSAGE)
        setShowMessage(true)
        setTimeout(() => history.push(ROUTES.SETTINGS), 3000)
      },
    }
  )

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const handleChangeTimezone = (timezone) => {
    if (!timezone) return
    setTimezone(timezone)
    updateTimezone(timezone)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    setShowCopyTooltip(true)
  }

  const setDatesFromRange = (dateRange) => {
    let startDate, endDate
    switch (dateRange) {
      case 'last_7_days':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(7, 'days').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_30_days':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(30, 'days').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_3_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(3, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_6_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(6, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_12_months':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(12, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_2_years':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(2, 'years').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_3_years':
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(3, 'years').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'ytd':
        startDate = moment.tz(TIMEZONE).startOf('year').startOf('day').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'last_and_this_year':
        startDate = moment.tz(TIMEZONE).subtract(1, 'year').startOf('year').startOf('day').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      case 'all_time':
        startDate = moment.tz('1970', TIMEZONE).startOf('day').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
      default:
        startDate = moment.tz(TIMEZONE).startOf('day').subtract(12, 'months').toISOString()
        endDate = moment.tz(TIMEZONE).endOf('day').toISOString()
        break
    }
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return (
    <div className={classes.root}>
      <Box padding={2}>
        <Button
          variant='outlined'
          onClick={() => history.push(ROUTES.SETTINGS)}
          size='small'
        >
          Back
        </Button>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                  <img
                    className={classes.logoImg}
                    alt='sticky_logo'
                    src={stickyLogoImg}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='h5' paragraph>
                    Sticky Source
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : `${integration.nickname}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Account ID
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : `${integration.platform_id}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Postback URL
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box>
                    <Box display='block'>
                      <Typography variant='body2' paragraph>
                        Two postbacks must be setup manually in your Sticky account prior to syncing data below to ensure that no data is missed.
                      </Typography>
                      <Typography variant='body2' paragraph>
                        To create a postback inside Sticky, login to your account, then use the navigation menu at the top to go to Settings &gt; POST BACKS. Press ACTIONS &gt; Create.
                      </Typography>
                      <Typography variant='body2' paragraph>
                        On the postback creation page, set <b>Name</b> to &quot;LTV Numbers&quot;. Set <b>HTTP Method</b> to &quot;POST&quot;. Set <b>Type</b> to &quot;Order&quot;.
                      </Typography>
                      <Typography variant='body2' paragraph>
                        For <b>URL</b>, copy the LTV Numbers URL below and paste the value in the <b>URL</b> field in Sticky. Then press the button &quot;VIEW TOKENS / BUILD URL&quot;. Leave <b>Protocol</b> as &quot;https://&quot;.
                        Press the checkbox to the left of the word &quot;Token&quot; to select all parameters listed. Scroll down to the bottom and press &quot;SAVE&quot;.
                      </Typography>
                    </Box>
                    <Box>
                      {isLoadingIntegration ? <Skeleton variant='text' width={100} /> : (
                        <Box display='flex' flexDirection='row' width='100%'>
                          <InputBase
                            variant='filled'
                            value={webhookUrl}
                            size='small'
                            fullWidth
                            disabled
                          />
                          <Tooltip
                            open={showCopyTooltip}
                            onClose={() => setShowCopyTooltip(false)}
                            leaveDelay={2000}
                            placement='right'
                            title='Copied'
                          >
                            <Button
                              variant='text'
                              color='primary'
                              onClick={() => copyToClipboard(webhookUrl)}
                            >
                              Copy
                            </Button>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                    <Box marginTop={2}>
                      <Typography variant='body2' paragraph>
                        For <b>Trigger Type</b>, select &quot;Order Type&quot;. For <b>Order Type</b>, select &quot;Both&quot;.
                        For <b>Order Status</b>, select &quot;Both&quot;. For <b>Payments</b>, select &quot;Both&quot;.
                        Check the box for <b>Global</b> to fire the post back for all campaigns.
                        Press &quot;SAVE&quot; to create the post back.
                      </Typography>
                      <Typography variant='body2' paragraph>
                        Now we need a second postback for refunds. To create the second postback, locate the postback you just created, click the three dots menu and select Copy. Then edit the newly created postback.
                      </Typography>
                      <Typography variant='body2' paragraph>
                        Add &quot;refund&quot; to the end of the name. Then change <b>Trigger Type</b> to &quot;Order Action&quot;. Then set <b>Action</b> to &quot;Void&quot;, &quot;Refund&quot;, and &quot;Chargeback&quot;. Then press &quot;SAVE&quot;.
                      </Typography>
                      <Typography variant='body2' paragraph>
                        Once this post back has been created, you can proceed with syncing the data using the options below.
                      </Typography>

                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Timezone
                  </Typography>
                </Grid>

                <Grid item xs={10}>
                  <Typography variant='body2' paragraph>
                    Your Sticky Account provides data in the same timezone that your account profile is set to. This value must be set properly below to ensure that times are interpreted correctly. Incorrectly setting this value can result in payment times being incorrectly offset from the true value.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    To find the time zone value for your account, use the top navigation menu to go to Settings &gt; ACCOUNTS & PERMISSIONS.
                    Find the user that is the original administrator of the account, and click the edit button on the right side of the table that looks like a pencil.
                    Note that it is very important that you find the original administrator of the account, as this is the only user that dictates the timezone of the
                    data in the account. On the edit page, find &quot;Time Zone&quot; value and find a matching value in the <b>Timezone</b> dropdown below.
                  </Typography>
                  <Typography variant='body2' paragraph>
                    It is strongly recommended that you do NOT change this value in your Sticky account after setting it below, or it can cause date and time inconsistencies that could affect the integrity of the data.
                  </Typography>

                  <Box marginTop={0} display='flex' alignItems='center'>
                    <FormControl
                      variant='outlined'
                      margin='dense'
                      sx={{ margin: 1, minWidth: 300 }}
                      required
                     >
                      {isLoadingIntegration ? <Skeleton variant='rect' width={300} /> : (
                        <Autocomplete
                          id='timezone'
                          options={moment.tz.names()}
                          value={timezone}
                          onChange={event => handleChangeTimezone(event.target.textContent)}
                          renderInput={(params) => <TextField {...params} label='Timezone' />}
                          loading={isUpdatingTimezone}
                        />
                      )}
                    </FormControl>
                  </Box>

                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Sync
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' paragraph>
                    To sync payment data from Sticky with LTV Numbers, select the appropriate date range and click the Sync button below. This will start a process of retrieving data from the account and loading it into LTV Numbers. Due to rate limitations inside
                    Sticky, this process can take anywhere from several minutes to several hours. <b>Please note that you must create a post back using the above before syncing data if you would like to bring in new data in real time.</b>
                  </Typography>
                  <Typography variant='body2' paragraph>
                    Use the selector below to specify the date range for which you would like to import the data. It defaults to the last 2 years, but you can change it to whatever makes sense for analysis purposes.
                  </Typography>

                  <Box marginTop={2} display='flex' alignItems='center'>
                    <FormControl
                      variant='outlined'
                      margin='dense'
                      sx={{ margin: 1, minWidth: 150 }}
                      required
                      >
                      <InputLabel id='data-type-label'>Date Range</InputLabel>
                      <Select
                        labelId='date-range-label'
                        id='date-range-select'
                        value={dateRange}
                        onChange={event => setDateRange(event.target.value)}
                        label='Date Range'
                      >
                        {dateRangeMenuItems.map(item =>
                          <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box>
                    <Button
                      className={classes.syncButton}
                      variant='contained'
                      color='secondary'
                      onClick={() => sync()}
                      sx={{ width: 110 }}
                      disabled={ !dateRange }
                    >
                      {isSyncing ?
                        <CircularProgress color='inherit' size={24} className={classes.buttonProgress} />
                        : 'Sync Data'
                      }
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Delete
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' paragraph>
                    If you want to remove this data integration so transacations can no longer be saved, click the button below.
                  </Typography>
                  <Button
                    className={classes.redButton}
                    variant='text'
                    onClick={() => setShowRevokeConfirmation(true)}
                  >
                    Delete Source
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Dialog
        open={showRevokeConfirmation}
        onClose={() => setShowRevokeConfirmation(false)}
      >
        <DialogTitle>
          Remove access to account <b>&quot;{integration.nickname}&quot;</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will prevent data from being synced to LTV Numbers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.redButton}
            variant='text'
            onClick={() => setShowRevokeConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => revoke()}
          >
            {isLoadingRevoke ? <CircularProgress color='inherit' size={24} /> : 'Remove'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={3000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

StickySource.propTypes = {}

export default StickySource