import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Popover from '@mui/material/Popover'

import { useEnhancerPlatforms } from '../../contexts/EnhancerContext'

import { COHORT_BREAKDOWN_FIELDS, CONTACT_FIELDS, ENHANCER_FIELDS, PAYMENT_FIELDS } from '../../constants'
import { capitalizeFirstLetter } from '../../utils/helpers'

const BreakdownSelect = ({
  segmentId,
  kind,
  fieldType,
  breakdownLabel,
  onSegmentBreakdownSelect,
  includeCohortFields,
}) => {
  const { enhancerPlatforms } = useEnhancerPlatforms()

  const breakdownItems = useMemo(() => {
    let newBreakdownItems = fieldType === 'contact' ? CONTACT_FIELDS : PAYMENT_FIELDS
    if (enhancerPlatforms.length > 0) {
      enhancerPlatforms.forEach(platform => {
        newBreakdownItems = [...newBreakdownItems, ...ENHANCER_FIELDS[platform]]
      })
    }
    return newBreakdownItems
  }, [fieldType, enhancerPlatforms])

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleBreakdownSelect = (breakdownItem) => {
    const field = breakdownItem.id
    const enhancer = breakdownItem.enhancer || null
    const breakdown = {
      field,
      kind,
      type: { id: fieldType },
      enhancer
    }
    onSegmentBreakdownSelect(segmentId, breakdown)
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button
        variant='text'
        size='small'
        color='secondary'
        onClick={handleClick}
      >
        {breakdownLabel ? breakdownLabel : 'Breakdown'}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        sx={{
          marginTop: theme => theme.spacing(1)
        }}
      >
        <Box>
          <List disablePadding sx={{ maxHeight: 545 }}>
            {includeCohortFields && (
              <React.Fragment>
                <ListSubheader>Cohorts</ListSubheader>
                {COHORT_BREAKDOWN_FIELDS.map(item =>
                  <ListItemButton
                    key={item.id}
                    onClick={() => handleBreakdownSelect(item)}
                    dense
                  >
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{ style: { fontSize: 16 } }}
                    />
                  </ListItemButton>
                )}
                <ListSubheader>General</ListSubheader>
              </React.Fragment>
            )}
            {Object.entries(breakdownItems.reduce((acc, item) => {
              const enhancer = item.enhancer || 'General'
              if (!acc[enhancer]) {
                acc[enhancer] = []
              }
              acc[enhancer].push(item)
              return acc
            }, {})).map(([enhancer, items]) => (
              <React.Fragment key={enhancer}>
                {enhancer !== 'General' && <ListSubheader>{capitalizeFirstLetter(enhancer)}</ListSubheader>}
                {items.map(item => (
                  <ListItemButton
                    key={item.id}
                    onClick={() => handleBreakdownSelect(item)}
                    dense
                  >
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{ style: { fontSize: 16 } }}
                    />
                  </ListItemButton>
                ))}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Popover>
    </Box>
  )
}

BreakdownSelect.defaultProps = {
  kind: 'all',
  fieldType: 'payment',
}

BreakdownSelect.propTypes = {
  segmentId: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(['all', 'frontend', 'backend']).isRequired,
  fieldType: PropTypes.oneOf(['contact', 'payment']).isRequired,
  breakdownLabel: PropTypes.string,
  onSegmentBreakdownSelect: PropTypes.func.isRequired,
  includeCohortFields: PropTypes.bool,
}

export default BreakdownSelect
