import React, { createContext, useContext, useState, useEffect } from 'react'
import { FirebaseContext } from '../utils/firebase'
import 'firebase/auth'
import { API_ROOT_URL } from '../constants/'

const EnhancerContext = createContext()

export const EnhancerProvider = ({ children }) => {
  const firebase = useContext(FirebaseContext)
  const [enhancerPlatforms, setEnhancerPlatforms] = useState([])

  const fetchEnhancerPlatforms = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken(false)
      const response = await fetch(`${API_ROOT_URL}/api_fs/enhancers/platforms`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
      const data = await response.json()
      if (Array.isArray(data)) {
        setEnhancerPlatforms(data)
      } else {
        console.error('Unexpected response format:', data)
        setEnhancerPlatforms([])
      }
    } catch (error) {
      console.error('Failed to fetch enhancer platforms:', error)
      setEnhancerPlatforms([])
    }
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        fetchEnhancerPlatforms()
      }
    })

    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, [firebase])

  return (
    <EnhancerContext.Provider value={{ enhancerPlatforms, fetchEnhancerPlatforms }}>
      {children}
    </EnhancerContext.Provider>
  )
}

export const useEnhancerPlatforms = () => {
  return useContext(EnhancerContext)
}