import React from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import Grid from '@mui/material/Grid'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Snackbar from '@mui/material/Snackbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import makeStyles from '@mui/styles/makeStyles'
import { useTheme } from '@mui/styles'

import { useHistory, useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'
import { useEnhancerPlatforms } from '../../contexts/EnhancerContext'

import { API_ROOT_URL } from '../../constants'
import * as ROUTES from '../../constants/routes'

import hyrosLogoImg from '../../media/hyros_logo.png'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.grey[50],
  },
  logoImg: {
    width: 100,
  },
  syncButton: {
    width: 110,
  },
  redButton: {
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(255,23,68,0.04)',
    },
  },
}))

const REVOKE_SUCCESS_MESSAGE = 'Account removed. Data will no longer be synced from this account. Redirecting back to settings...'

const HyrosEnhancer = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { id } = useParams()
  const history = useHistory()
  const firebase = React.useContext(FirebaseContext)
  const { fetchEnhancerPlatforms } = useEnhancerPlatforms()

  const [showCopyTooltip, setShowCopyTooltip] = React.useState(false)

  const [message, setMessage] = React.useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [messageSeverity, setMessageSeverity] = React.useState('success')
  const [showRevokeConfirmation, setShowRevokeConfirmation] = React.useState(false)
  const [initialIntegrationIds, setInitialIntegrationIds] = React.useState([])
  const [selectedIntegrationIds, setSelectedIntegrationIds] = React.useState([])

  const {
    isLoading: isLoadingEnhancer,
    data: enhancer = {}
  } = useQuery(['enhancer-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/enhancers/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 15 * 60 * 1000,  // 15 minutes
      staleTime: 15 * 60 * 1000,  // 15 minutes
      refetchOnWindowFocus: false,
    }
  )

  const {
    isLoading: isLoadingEnhancerIntegrationIds,
  } = useQuery(['enhancer-integrations-get', id], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/enhancers/${id}/integrations`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setInitialIntegrationIds(data)
          setSelectedIntegrationIds(data)
        }
      }
    }
  )

  const { isLoading: isLoadingRevoke, refetch: revoke } = useQuery(['revoke-hyros'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/enhancers/hyros/revoke`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          enhancer_id: id
        })
      }).then(res => res.json())
    }),
    {
      enabled: false,
      staleTime: Infinity,
      refetchOnReconnect: false,
      onSuccess: () => {
        setShowRevokeConfirmation(false)
        setMessage(REVOKE_SUCCESS_MESSAGE)
        setMessageSeverity('success')
        setShowMessage(true)
        fetchEnhancerPlatforms()
        setTimeout(() => history.push(ROUTES.SETTINGS), 3000)
      },
    }
  )

  const { isLoading: isUpdatingEnhancerIntegrations, mutate: updateEnhancerIntegrations } = useMutation(
    newIntegrationIds => firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/enhancers/integrations`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          enhancer_id: id,
          integration_ids: newIntegrationIds,
        }),
      }).then(res => res.json())
    }),
    {
      onSuccess: () => {
        setInitialIntegrationIds(selectedIntegrationIds)
        setShowMessage(true)
        setMessage('Data Sources successfully updated.')
        setMessageSeverity('success')
      },
      onError: () => {
        setSelectedIntegrationIds(initialIntegrationIds)
        setShowMessage(true)
        setMessage('Error updating Data Sources. Selected sources reverted. Please try again, and if problem persists, contact support.')
        setMessageSeverity('error')
      }
    }
  )

  const {
    isLoading: isLoadingIntegrations,
    data: integrations = []
  } = useQuery(['integrations-list'], () =>
    firebase.auth().currentUser.getIdToken(false).then(token => {
      return fetch(`${API_ROOT_URL}/api_fs/integrations`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }).then(res => res.json())
    }),
    {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  )

  const arraysEqual = (a, b) => {
    if (a.length !== b.length) return false
    const sortedA = [...a].sort()
    const sortedB = [...b].sort()
    return sortedA.every((value, index) => value === sortedB[index])
  }

  const handleCloseMessage = (_, reason) => {
    if (reason === 'clickaway') return
    setShowMessage(false)
  }

  const handleIntegrationChange = (integrationId) => {
    setSelectedIntegrationIds(prevSelected => {
      if (prevSelected.includes(integrationId)) {
        return prevSelected.filter(id => id !== integrationId)
      } else {
        return [...prevSelected, integrationId]
      }
    })
  }

  const handleClear = () => {
    setSelectedIntegrationIds([])
  }

  const handleSelectAll = () => {
    setSelectedIntegrationIds(integrations.map(integration => integration.id))
  }

  const handleUpdateEnhancerIntegrations = () => {
    updateEnhancerIntegrations(selectedIntegrationIds)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    setShowCopyTooltip(true)
  }

  const maskText = (apiKey) => {
    if (!apiKey) return ''
    const visibleChars = 4
    const maskedSection = apiKey.slice(visibleChars, -visibleChars).replace(/./g, '*')
    return apiKey.slice(0, visibleChars) + maskedSection + apiKey.slice(-visibleChars)
  }

  const dataSourcesChanged = !arraysEqual(initialIntegrationIds, selectedIntegrationIds)

  return (
    <div className={classes.root}>
      <Box padding={2}>
        <Button
          variant='outlined'
          onClick={() => history.push(ROUTES.SETTINGS)}
          size='small'
        >
          Back
        </Button>
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                  <img
                    className={classes.logoImg}
                    alt='hyros_logo'
                    src={hyrosLogoImg}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='h5' gutterBottom>
                    Hyros Enhancer
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        {/* Account ID */}
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Account ID
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingEnhancer ? <Skeleton variant='text' width={100} /> : `${enhancer.platform_id}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        {/* Name */}
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box height='100%' display='flex' alignItems='center'>
                    <Typography variant='body2'>
                      {isLoadingEnhancer ? <Skeleton variant='text' width={100} /> : `${enhancer.nickname}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        {/* API Key */}
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    API Key
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box display='flex' alignItems='center'>
                    {isLoadingEnhancer ? <Skeleton variant='text' width={100} /> : (
                      <Box display='flex' flexDirection='row' width='100%'>
                        <InputBase
                          variant='filled'
                          value={maskText(enhancer.api_key)}
                          size='small'
                          fullWidth
                          disabled
                        />
                        <Tooltip
                          open={showCopyTooltip}
                          onClose={() => setShowCopyTooltip(false)}
                          leaveDelay={2000}
                          placement='right'
                          title='Copied'
                        >
                          <Button
                            variant='text'
                            color='primary'
                            onClick={() => copyToClipboard(enhancer.api_key)}
                          >
                            Copy
                          </Button>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        {/* Data Sources */}
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Data Sources
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Box display='block'>
                    <Typography variant='body2' paragraph>
                      Select the Data Sources that you would like to be enhanced by this Hyros Enhancer. By selecting a Data Source below,
                      any contact or payment data that comes from that Data Source will then be enhanced by Hyros to populate additional fields
                      that will be available in LTV Numbers.
                    </Typography>
                    <Typography variant='body2' paragraph>
                      There will be a short delay before enhancing a record to ensure that data is available in Hyros when requested.
                    </Typography>
                  </Box>

                  {/* Integrations List */}
                  <Box
                    marginTop={1}
                    marginBottom={1}
                    border={1}
                    borderColor={theme.palette.divider}
                    borderRadius={1}
                    padding={1}
                    maxHeight={400}
                    overflow='auto'
                  >
                    {isLoadingIntegrations || isLoadingEnhancerIntegrationIds ? (
                      <Skeleton variant='text' width='100%' />
                    ) : (
                      <List
                        dense
                      >
                        {integrations
                          .sort((a, b) => a.nickname.localeCompare(b.nickname))
                          .map(integration => {
                            const labelId = `checkbox-list-label-${integration.id}`
                            return (
                              <ListItem
                                key={integration.id}
                                dense
                                disablePadding
                                sx={{ bgcolor: selectedIntegrationIds.includes(integration.id) ? theme.palette.action.selected : 'inherit' }}
                              >
                                <ListItemButton
                                  onClick={() => handleIntegrationChange(integration.id)}
                                  dense
                                  sx={{ height: 40 }}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge='start'
                                      checked={selectedIntegrationIds.includes(integration.id)}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText id={labelId} primary={`${integration.nickname} (${integration.platform})`} />
                                </ListItemButton>
                              </ListItem>
                            )
                          })}
                      </List>
                    )}
                  </Box>
                  <Box>
                    <Typography variant='body2'>
                      {selectedIntegrationIds.length} Data Sources selected for enhancement
                      {dataSourcesChanged && (<span>&nbsp;<b>(unsaved changes)</b></span>)}
                    </Typography>
                  </Box>
                  <Box marginTop={2} display='flex' justifyContent='flex-start' columnGap={1}>
                    <Button variant='outlined' onClick={handleClear}>Clear</Button>
                    <Button variant='outlined' onClick={handleSelectAll}>Select All</Button>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleUpdateEnhancerIntegrations}
                      disabled={!dataSourcesChanged}
                    >
                      {isUpdatingEnhancerIntegrations ? <CircularProgress color='inherit' size={24} /> : 'Save'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        {/* Delete Enhancer */}
        <Box marginTop={2}>
          <Paper>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant='h6'>
                    Delete
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant='body2' gutterBottom>
                    If you want to remove this data enhancer so transacations can no longer be saved, click the button below.
                  </Typography>
                  <Button
                    className={classes.redButton}
                    variant='text'
                    onClick={() => setShowRevokeConfirmation(true)}
                  >
                    Delete Enhancer
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Dialog
        open={showRevokeConfirmation}
        onClose={() => setShowRevokeConfirmation(false)}
      >
        <DialogTitle>
          Remove access to <b>{enhancer.nickname}</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will prevent data from being enhanced.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.redButton}
            variant='text'
            onClick={() => setShowRevokeConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => revoke()}
          >
            {isLoadingRevoke ? <CircularProgress color='inherit' size={24} /> : 'Remove'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showMessage}
        autoHideDuration={3000}
        onClose={handleCloseMessage}
      >
        <Alert
          severity={messageSeverity}
          variant='filled'
          onClose={handleCloseMessage}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

HyrosEnhancer.propTypes = {}

export default HyrosEnhancer