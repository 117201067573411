import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'

import DarkTooltip from './DarkTooltip'

const ColumnsSelect = ({
  parentKey,
  summaryColumns,
  columnsKey,
  onColumnsKeyChange,
}) => {
  // Safety check in case the columnsKey doesn't exist in summaryColumns
  if (!(columnsKey in summaryColumns)) return null

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleColumnsSelect = (value) => {
    onColumnsKeyChange(value)
    setAnchorEl(null)
  }

  const columnsSelectItems = useMemo(() => {
    return Object.keys(summaryColumns).map(key => {
      const item = summaryColumns[key]
      return {
        key,
        name: item.name,
        description: item.description,
      }
    })
  }, [parentKey])

  const selectedColumnsName = columnsSelectItems.find(item => item.key === columnsKey).name

  return (
    <React.Fragment>
      <DarkTooltip
        title='Choose columns'
        placement='bottom'
        arrow
      >
        <Button
          variant='outlined'
          onClick={handleClick}
          startIcon={ <ViewColumnIcon /> }
          endIcon={ <ArrowDropDownIcon /> }
          sx={{
            textTransform: 'none',
          }}
        >
          {selectedColumnsName}
        </Button>
      </DarkTooltip>
      <Popover
        sx={{
          marginTop: theme => theme.spacing(1),
        }}
        id={anchorEl ? 'popover-columns' : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
      >
        <Box>
          <List component='ul' aria-label='breakdown selector' dense subheader={<ListSubheader>Choose columns</ListSubheader>}>
            {columnsSelectItems.map(item =>
              <ListItemButton
                key={item.key}
                selected={columnsKey === item.key}
                onClick={() => handleColumnsSelect(item.key)}
                dense
              >
                <ListItemText
                  primary={item.name}
                  secondary={item.description}
                />
              </ListItemButton>
            )}
          </List>
          {columnsSelectItems.length === 1 && (
            <Box paddingX={2} paddingBottom={1}>
              <Typography variant='caption' color='text.secondary'>
                No other columns to choose
              </Typography>
            </Box>
          )}
        </Box>
      </Popover>
    </React.Fragment>
  )
}

ColumnsSelect.propTypes = {
  parentKey: PropTypes.string.isRequired,
  summaryColumns: PropTypes.object.isRequired,
  columnsKey: PropTypes.string.isRequired,
  onColumnsKeyChange: PropTypes.func.isRequired,
}

export default ColumnsSelect
