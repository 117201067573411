const platforms = require('./platforms')

exports.API_ROOT_URL = process.env.REACT_APP_ENV === 'production' ?
 'https://us-central1-ltv-numbers-prod.cloudfunctions.net/'
 : 'https://us-central1-ltv-numbers-dev.cloudfunctions.net/'
exports.SITE_ROOT_URL = process.env.NODE_ENV === 'production' ? (
  process.env.REACT_APP_ENV === 'production' ? 'https://app.ltvnumbers.com' : 'https://dev.ltvnumbers.com'
) : 'http://localhost:3000'

exports.ALL_DATA_KEY = '[ALL]'
exports.NONE_LABEL = {id: 'none', name: 'None'}
exports.NULL_REPLACEMENT_KEY = '[NULL]'

exports.BLUE_ACCENT_COLOR = '#2267ea'
exports.DEFAULT_SEGMENT_COLOR = '#4e86ee'
exports.SEGMENT_COLOR_PALETTE = [
  this.DEFAULT_SEGMENT_COLOR,
  '#e6194b',
  '#3cb44b',
  '#ffe119',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#f0e68c',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd0a0'
]

exports.DASHBOARD_BACKEND_BREAKDOWN_ROWS_LIMIT = 5

exports.SHOPIFY_APP_URL = process.env.NODE_ENV === 'production' ? (
  process.env.REACT_APP_ENV === 'production' ? 'https://app.ltvnumbers.com' : 'https://dev.ltvnumbers.com'
) : 'http://localhost:3000'
exports.SHOPIFY_SCOPES = 'read_customers,read_orders,read_all_orders'

exports.PUBLIC_DEMO_UID = 'ICZm43VVohUcOzdGHp453zmkH7G3'

exports.FIRESTORE_COLLECTIONS = {
  GROUPS: 'groups',
  REPORTS: 'reports',
  SCORECARDS: 'scorecards',
  SEGMENTS: 'segments',
  TARGETS: 'targets',
  USERS: 'users',
}

exports.CURRENCY_DEFAULT = 'usd'
exports.CURRENCY_ITEMS = [
  {
    id: 'usd',
    name: '$ (US Dollar, USD)',
  },
  {
    id: 'gbp',
    name: '£ (British Pound Sterling, GBP)',
  },
  {
    id: 'eur',
    name: '€ (Euro, EUR)',
  },
]
exports.CURRENCY_SYMBOL_MAP = {
  usd: '$',
  gbp: '£',
  eur: '€',
}

exports.CONTACT_FIELDS = [
  {id: 'platform', name: 'Platform'},
  {id: 'integration_id', name: 'Data Source'},
  {id: 'funnel_id', name: 'Funnel'},
  {id: 'utm_source', name: 'utm_source'},
  {id: 'utm_medium', name: 'utm_medium'},
  {id: 'utm_campaign', name: 'utm_campaign'},
  {id: 'utm_content', name: 'utm_content'},
  {id: 'utm_term', name: 'utm_term'},
  {id: 'affiliate_id_1', name: 'Affiliate ID 1'},
  {id: 'affiliate_id_2', name: 'Affiliate ID 2'},
  {id: 'country', name: 'Country'},
]
exports.PAYMENT_FIELDS = [
  {id: 'platform', name: 'Platform'},
  {id: 'integration_id', name: 'Data Source'},
  {id: 'role', name: 'Role'},
  {id: 'funnel_id', name: 'Funnel'},
  {id: 'product_id', name: 'Product'},
  {id: 'productVariant_id', name: 'Product Variant'},
  {id: 'utm_source', name: 'utm_source'},
  {id: 'utm_medium', name: 'utm_medium'},
  {id: 'utm_campaign', name: 'utm_campaign'},
  {id: 'utm_content', name: 'utm_content'},
  {id: 'utm_term', name: 'utm_term'},
  {id: 'affiliate_id_1', name: 'Affiliate ID 1'},
  {id: 'affiliate_id_2', name: 'Affiliate ID 2'},
  {id: 'seller_id', name: 'Seller ID'},
  {id: 'country', name: 'Country'},
]

exports.ENHANCER_FIELDS = {
  [platforms.HYROS]: [
    {enhancer: 'hyros', id: 'first_source_kind', name: 'First Source: Kind'},
    {enhancer: 'hyros', id: 'first_source_traffic_source', name: 'First Source: Traffic Source'},
    {enhancer: 'hyros', id: 'first_source_ad_platform', name: 'First Source: Ad Platform'},
    {enhancer: 'hyros', id: 'first_source_ad_account', name: 'First Source: Ad Account'},
    {enhancer: 'hyros', id: 'first_source_campaign', name: 'First Source: Campaign'},
    {enhancer: 'hyros', id: 'first_source_ad_set', name: 'First Source: Ad Set'},
    {enhancer: 'hyros', id: 'first_source_ad', name: 'First Source: Ad'},
    {enhancer: 'hyros', id: 'last_source_kind', name: 'Last Source: Kind'},
    {enhancer: 'hyros', id: 'last_source_traffic_source', name: 'Last Source: Traffic Source'},
    {enhancer: 'hyros', id: 'last_source_ad_platform', name: 'Last Source: Ad Platform'},
    {enhancer: 'hyros', id: 'last_source_ad_account', name: 'Last Source: Ad Account'},
    {enhancer: 'hyros', id: 'last_source_campaign', name: 'Last Source: Campaign'},
    {enhancer: 'hyros', id: 'last_source_ad_set', name: 'Last Source: Ad Set'},
    {enhancer: 'hyros', id: 'last_source_ad', name: 'Last Source: Ad'},
  ],
  [platforms.RECHARGE]: [
    {enhancer: 'recharge', id: 'subscription_type', name: 'Subscription/One-Time'},
    {enhancer: 'recharge', id: 'order_type', name: 'Order Type'},
    {enhancer: 'recharge', id: 'order_interval', name: 'Order Interval'},
    {enhancer: 'recharge', id: 'in_recharge', name: 'Order In Recharge?'},
  ]
}

exports.COHORT_BREAKDOWN_FIELDS = [
  {id: 'cohort|2', name: 'Monthly Cohorts'},
  {id: 'cohort|3', name: 'Quarterly Cohorts'},
  {id: 'cohort|4', name: 'Yearly Cohorts'},
]
exports.COHORT_BREAKDOWN_KEY = 'cohort'

exports.DATETIME_RESOLUTION_MAP = {
  0: 'day',
  1: 'week',
  2: 'month',
  3: 'quarter',
  4: 'year',
}
exports.DAY_RESOLUTION_MAP = {
  0: 1,
  1: 3,
  2: 5,
  3: 7,
  4: 10,
  5: 30,
}

exports.DEFAULT_REPORT_KEY = 'sales_overview'
exports.DEFAULT_TARGET_METRIC_KEY = 'revenue_gross'

exports.TABLE_SUMMARY_TAB_KEY = 'summary'
exports.TABLE_CHART_TAB_KEY = 'chart'
exports.TABLE_MILESTONES_TAB_KEY = 'milestones'