import React from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import makeStyles from '@mui/styles/makeStyles'

import DataSources from './DataSources'
import Enhancers from './Enhancers'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 66,
    right: 0,
    width: 'calc(100% - 66px)',
    height: '100vh',
    overflowY: 'auto',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
}))

const Settings = () => {
  const classes = useStyles()

  const [selectedTab, setSelectedTab] = React.useState(0)

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <div className={classes.root} style={{ display: 'flex', height: '100vh' }}>
      <Tabs
        orientation="vertical"
        value={selectedTab}
        onChange={handleTabChange}
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label='Data Sources' />
        <Tab label='Enhancers' />
      </Tabs>
      <Box padding={2} style={{ flexGrow: 1, overflowY: 'auto' }}>
        {selectedTab === 0 && (
          <DataSources />
        )}
        {selectedTab === 1 && (
          <Enhancers />
        )}
      </Box>
    </div>
  )
}

export default Settings