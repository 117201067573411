const METRIC_ITEMS_MAP = {
  'aogp': {
    'key': 'aogp',
    'name': 'Gross Profit per Order',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'aogp_frontend': {
    'key': 'aogp_frontend',
    'name': 'Gross Profit per Order (Frontend)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'aogp_backend': {
    'key': 'aogp_backend',
    'name': 'Gross Profit per Order (Backend)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'aogpc': {
    'key': 'aogpc',
    'name': 'AOGP Contribution',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'aogpc_rate': {
    'key': 'aogpc_rate',
    'name': 'AOGP Contribution Rate',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'aov': {
    'key': 'aov',
    'name': 'AOV',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'aovc': {
    'key': 'aovc',
    'name': 'AOV Contribution',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'aovc_rate': {
    'key': 'aovc_rate',
    'name': 'AOV Contribution Rate',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'aov_frontend': {
    'key': 'aov_frontend',
    'name': 'AOV (Frontend)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'aov_backend': {
    'key': 'aov_backend',
    'name': 'AOV (Backend)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'confidence': {
    'key': 'confidence',
    'name': 'Confidence',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'count': {
    'key': 'count',
    'name': 'Eligible People',
    'format': 'integer',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'cost': {
    'key': 'cost',
    'name': 'Total Cost',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'costc': {
    'key': 'costc',
    'name': 'Average Cost Contribution',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'costc_rate': {
    'key': 'costc_rate',
    'name': 'Cost Contribution Rate',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'cost_orders': {
    'key': 'cost_orders',
    'name': 'Order Costs',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'cost_per_order': {
    'key': 'cost_per_order',
    'name': 'Cost per Order',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'customers': {
    'key': 'customers',
    'name': 'Customers',
    'format': 'integer',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'customers_frontend': {
    'key': 'customers_frontend',
    'name': 'New Customers',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'customers_frontend_rate': {
    'key': 'customers_frontend_rate',
    'name': 'New Customer Rate',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'customers_backend': {
    'key': 'customers_backend',
    'name': 'Returning Customers',
    'cumulativeName': 'Returning Customers, non-unique',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'customers_backend_rate': {
    'key': 'customers_backend_rate',
    'name': 'Returning Customer Rate',
    'cumulativeName': 'Returning Customer Rate, non-unique',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'customers_backend_rate_j': {
    'key': 'customers_backend_rate_j',
    'name': 'Returning Customer Rate (Journey)',
    'cumulativeName': 'Returning Customer Rate (Journey), non-unique',
    'format': 'percent',
    'allowCumulative': true,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'gross_profit': {
    'key': 'gross_profit',
    'name': 'Gross Profit',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'gross_profit_frontend': {
    'key': 'gross_profit_frontend',
    'name': 'Gross Profit (Frontend)',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'gross_profit_backend': {
    'key': 'gross_profit_backend',
    'name': 'Gross Profit (Backend)',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'gross_profit_orders': {
    'key': 'gross_profit_orders',
    'name': 'Order Gross Profit',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'leads': {
    'key': 'leads',
    'name': 'Leads',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'order_rate': {
    'key': 'order_rate',
    'name': 'Order Rate',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'orders': {
    'key': 'orders',
    'name': 'Orders',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'orders_frontend': {
    'key': 'orders_frontend',
    'name': 'Orders (Frontend)',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'orders_backend': {
    'key': 'orders_backend',
    'name': 'Orders (Backend)',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_backend_rate_j': {
    'key': 'revenue_backend_rate_j',
    'name': 'Returning Customer Revenue Added % (Journey)',
    'format': 'percent',
    'allowCumulative': true,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'revenue_net': {
    'key': 'revenue_net',
    'name': 'Net Revenue',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_net_frontend': {
    'key': 'revenue_net_frontend',
    'name': 'Net Revenue (Frontend)',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_net_backend': {
    'key': 'revenue_net_backend',
    'name': 'Net Revenue (Backend)',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_gross': {
    'key': 'revenue_gross',
    'name': 'Gross Revenue',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_gross_frontend': {
    'key': 'revenue_gross_frontend',
    'name': 'Gross Revenue (Frontend)',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_gross_backend': {
    'key': 'revenue_gross_backend',
    'name': 'Gross Revenue (Backend)',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_orders': {
    'key': 'revenue_orders',
    'name': 'Order Revenue',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_rebills': {
    'key': 'revenue_rebills',
    'name': 'Rebill Revenue',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'revenue_refunds': {
    'key': 'revenue_refunds',
    'name': 'Refund Amount',
    'format': 'currency',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'revenue_refunds_rate': {
    'key': 'revenue_refunds_rate',
    'name': 'Refund Rate',
    'format': 'percent',
    'allowCumulative': false,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'rebills': {
    'key': 'rebills',
    'name': 'Rebills',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': true,
  },
  'refunds': {
    'key': 'refunds',
    'name': 'Refunds',
    'format': 'integer',
    'allowCumulative': true,
    'allowScorecard': true,
    'allowTarget': false,
  },
  'ltv': {
    'key': 'ltv',
    'name': 'Lifetime Value',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltgpp': {
    'key': 'ltgpp',
    'name': 'Lifetime Gross Profit per Person',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltr': {
    'key': 'ltr',
    'name': 'Lifetime Revenue',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltgp': {
    'key': 'ltgp',
    'name': 'Lifetime Gross Profit',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'customers_new': {
    'key': 'customers_new',
    'name': 'New Customers',
    'format': 'integer',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_gr_acc': {
    'key': 'ltv_gr_acc',
    'name': 'LTV (Gross Revenue)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_nr_acc': {
    'key': 'ltv_nr_acc',
    'name': 'LTV (Net Revenue)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_gp_acc': {
    'key': 'ltv_gp_acc',
    'name': 'LTV (Gross Profit)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_cm_acc': {
    'key': 'ltv_cm_acc',
    'name': 'LTV (Contribution Margin)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_rf_acc': {
    'key': 'ltv_rf_acc',
    'name': 'LTV (Refunds)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltgr_acc': {
    'key': 'ltgr_acc',
    'name': 'Lifetime Gross Revenue',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltnr_acc': {
    'key': 'ltnr_acc',
    'name': 'Lifetime Net Revenue',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltgp_acc': {
    'key': 'ltgp_acc',
    'name': 'Lifetime Gross Profit',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltcm_acc': {
    'key': 'ltcm_acc',
    'name': 'Lifetime Contribution Margin',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltrf_acc': {
    'key': 'ltrf_acc',
    'name': 'Lifetime Refunds',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_frontend_gr': {
    'key': 'ltv_frontend_gr',
    'name': 'LTV Initial (Gross Revenue)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_frontend_gp': {
    'key': 'ltv_frontend_gp',
    'name': 'LTV Initial (Gross Profit)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_backend_gr': {
    'key': 'ltv_backend_gr',
    'name': 'LTV Backend (Gross Revenue)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_backend_gp': {
    'key': 'ltv_backend_gp',
    'name': 'LTV Backend (Gross Profit)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_backend_nr': {
    'key': 'ltv_backend_nr',
    'name': 'LTV Backend (Net Revenue)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_backend_cm': {
    'key': 'ltv_backend_cm',
    'name': 'LTV Backend (Contribution Margin)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_backend_rf': {
    'key': 'ltv_backend_rf',
    'name': 'LTV Backend (Refunds)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_lifetime_gr': {
    'key': 'ltv_lifetime_gr',
    'name': 'LTV Lifetime (Gross Revenue)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_lifetime_gp': {
    'key': 'ltv_lifetime_gp',
    'name': 'LTV Lifetime (Gross Profit)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_lifetime_nr': {
    'key': 'ltv_lifetime_nr',
    'name': 'LTV Lifetime (Net Revenue)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_lifetime_cm': {
    'key': 'ltv_lifetime_cm',
    'name': 'LTV Lifetime (Contribution Margin)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'ltv_lifetime_rf': {
    'key': 'ltv_lifetime_rf',
    'name': 'LTV Lifetime (Refunds)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'frontend_gr': {
    'key': 'frontend_gr',
    'name': 'Gross Revenue (Frontend)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'frontend_gp': {
    'key': 'frontend_gp',
    'name': 'Gross Profit (Frontend)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'backend_gr': {
    'key': 'backend_gr',
    'name': 'Gross Revenue (Backend)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'backend_gp': {
    'key': 'backend_gp',
    'name': 'Gross Profit (Backend)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'backend_nr': {
    'key': 'backend_nr',
    'name': 'Net Revenue (Backend)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'backend_cm': {
    'key': 'backend_cm',
    'name': 'Contribution Margin (Backend)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'backend_rf': {
    'key': 'backend_rf',
    'name': 'Refunds (Backend)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'lifetime_gr': {
    'key': 'lifetime_gr',
    'name': 'Gross Revenue (Lifetime)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'lifetime_gp': {
    'key': 'lifetime_gp',
    'name': 'Gross Profit (Lifetime)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'lifetime_nr': {
    'key': 'lifetime_nr',
    'name': 'Net Revenue (Lifetime)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'lifetime_cm': {
    'key': 'lifetime_cm',
    'name': 'Contribution Margin (Lifetime)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
  'lifetime_rf': {
    'key': 'lifetime_rf',
    'name': 'Refunds (Lifetime)',
    'format': 'currency',
    'allowCumulative': false,
    'allowScorecard': false,
    'allowTarget': false,
  },
}

export default METRIC_ITEMS_MAP