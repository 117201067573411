import React, { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import TableRowsIcon from '@mui/icons-material/TableRows'
import { useTheme } from '@mui/styles'

import AddFilterPopover from './AddFilterPopover'
import BreakdownSelect from './BreakdownSelect'
import ContextChipBreakdown from './ContextChipBreakdown'
import ContextChipFilter from './ContextChipFilter'
import DarkTooltip from './DarkTooltip'
import ColorPicker from './ColorPicker'

import { FirebaseContext } from '../../utils/firebase'
import { createDocument } from '../../utils/firestore'
import { disableBreakdownBasedOnCohortAllowance, getBreakdownFieldName, getSegmentById } from '../../utils/helpers'
import { FIRESTORE_COLLECTIONS } from '../../constants'

const SegmentBuilder = ({
  config,
  splitSegmentFrontBack,
  allowBreakdown,
  allowCohorts,
  allowOneSegmentOnly,
  segment,
  segmentEligibleBreakdownKeys,
  segments,
  aliases,
  savedSegments,
  savedGroups,
  onSegmentDelete,
  onSegmentDuplicate,
  onSegmentEditChange,
  onSegmentSaveEdit,
  onSegmentRevertEdit,
  onSegmentColorChange,
  onSegmentFilterAdd,
  onSegmentReplaceFiltersWithSavedSegmentFilter,
  onSegmentFilterDelete,
  onFilterAdd,
  onFilterUpdate,
  onFilterDelete,
  onClearAllSegmentFilters,
  onSegmentBreakdownSelect,
  onSegmentBreakdownColorChange,
  dragHandleProps,
}) => {
  let kinds = ['all']
  if (splitSegmentFrontBack) {
    kinds = ['frontend', 'backend']
  }

  return (
    <Box width='100%' display='flex' flexDirection='column' rowGap={0.25}>
      {kinds.map(kind => (
        <SegmentBuilderRow
          key={kind}
          kind={kind}
          config={config}
          allowBreakdown={allowBreakdown}
          allowCohorts={allowCohorts}
          allowOneSegmentOnly={allowOneSegmentOnly}
          segment={segment}
          segmentEligibleBreakdownKeys={segmentEligibleBreakdownKeys}
          segments={segments}
          aliases={aliases}
          savedSegments={savedSegments}
          savedGroups={savedGroups}
          onSegmentDelete={onSegmentDelete}
          onSegmentDuplicate={onSegmentDuplicate}
          onSegmentEditChange={onSegmentEditChange}
          onSegmentSaveEdit={onSegmentSaveEdit}
          onSegmentRevertEdit={onSegmentRevertEdit}
          onSegmentColorChange={onSegmentColorChange}
          onSegmentFilterAdd={onSegmentFilterAdd}
          onSegmentReplaceFiltersWithSavedSegmentFilter={onSegmentReplaceFiltersWithSavedSegmentFilter}
          onSegmentFilterDelete={onSegmentFilterDelete}
          onFilterAdd={onFilterAdd}
          onFilterUpdate={onFilterUpdate}
          onFilterDelete={onFilterDelete}
          onClearAllSegmentFilters={onClearAllSegmentFilters}
          onSegmentBreakdownSelect={onSegmentBreakdownSelect}
          onSegmentBreakdownColorChange={onSegmentBreakdownColorChange}
          dragHandleProps={dragHandleProps}
        />
      ))}
    </Box>
  )
}

const SegmentBuilderRow = ({
  kind,
  config,
  allowBreakdown,
  allowCohorts,
  allowOneSegmentOnly,
  segment,
  segmentEligibleBreakdownKeys,
  segments,
  aliases,
  savedSegments,
  savedGroups,
  onSegmentDelete,
  onSegmentDuplicate,
  onSegmentEditChange,
  onSegmentSaveEdit,
  onSegmentRevertEdit,
  onSegmentColorChange,
  onSegmentFilterAdd,
  onSegmentReplaceFiltersWithSavedSegmentFilter,
  onSegmentFilterDelete,
  onFilterAdd,
  onFilterUpdate,
  onFilterDelete,
  onClearAllSegmentFilters,
  onSegmentBreakdownSelect,
  onSegmentBreakdownColorChange,
  dragHandleProps,
}) => {
  const theme = useTheme()
  const firebase = useContext(FirebaseContext)
  const [segmentName, setSegmentName] = useState('')
  const [segmentCreateDialogOpen, setSegmentCreateDialogOpen] = React.useState(false)

  const handleSegmentCreateDialogOpen = () => {
    setSegmentCreateDialogOpen(true)
  }

  const handleSegmentCreateDialogClose = () => {
    setSegmentName('')
    setSegmentCreateDialogOpen(false)
  }

  const handleSegmentCreate = async (segmentId) => {
    const segmentToSave = getSegmentById(segments, segmentId)
    // Save the segment but set filter kind to 'all' so it can be used anywhere
    const newSegment = {
      segmentFilters: segmentToSave.segmentFilters.map(f => ({ ...f, kind: 'all' })),
      filters: segmentToSave.filters.map(f => ({ ...f, kind: 'all' })),
      name: segmentName
    }
    const newSegmentId = await createDocument(firebase, FIRESTORE_COLLECTIONS.SEGMENTS, newSegment)

    // When a segment is saved, we want to replace the filters in the segment with a single segment filter that references the saved segment
    onSegmentReplaceFiltersWithSavedSegmentFilter(segmentId, { ...newSegment, id: newSegmentId }, kind)

    setSegmentName('')
    setSegmentCreateDialogOpen(false)
  }

  const handleSegmentColorChange = (color) => {
    onSegmentColorChange(segment.id, color)
  }

  const getAllDataDisplayByRowType = (rowType) => {
    switch (rowType) {
      case 'all':
        return 'All Data'
      case 'frontend':
        return 'All New Customers'
      case 'backend':
        return 'All Returning Customers'
      default:
        return 'All Data'
    }
  }

  const activeBreakdownKeys = useMemo(() => {
    return Object.keys(segment.breakdownState)
      .filter(key => segmentEligibleBreakdownKeys.includes(key))
      .filter(key => segment.breakdownState[key].isActive)
  }, [segment.breakdownState, segmentEligibleBreakdownKeys])

  const filters = useMemo(() => {
    return segment.filters.filter(f => {
      if (kind === 'all') {
        return !f.kind || f.kind === kind
      } else {
        return f.kind === kind
      }
    })
  }, [segment, kind])

  const segmentFilters = useMemo(() => {
    return segment.segmentFilters.filter(f => {
      if (kind === 'all') {
        return !f.kind || f.kind === kind
      } else {
        return f.kind === kind
      }
    })
  }, [segment, kind])

  const breakdownName = useMemo(() => {
    if (segment.breakdown) {
      return getBreakdownFieldName(segment.breakdown)
    }
    return ''
  }, [segment.breakdown])

  const fieldType = useMemo(() => {
    if (kind === 'all' || kind === 'frontend') {
      return config.attribution === 'lead' ? 'contact' : 'payment'
    } else {
      return 'payment'
    }
  }, [kind, config.attribution])

  return (
    <Box>
      {/* Segment controls and filter row */}
      <Box width='100%' display='flex' flexDirection='row' alignItems='center' flexWrap='wrap' rowGap={0.5} columnGap={1}>
        {/* Only show the color picker on the first row */}
        {kind !== 'backend' ? (
          <ColorPicker
            type='segment'
            color={segment.isActive ? segment.color : theme.palette.grey[400]}
            width={12}
            height={12}
            onColorChange={handleSegmentColorChange}
          />
        ) : (
          <Box width={12} height={12} />  // Box for spacing consistency
        )}

        {/* For frontend and backend rowType, show a prefix */}
        {kind !== 'all' && (
          <Typography
            variant='overline'
            color='textSecondary'
            sx={{
              marginRight: theme => kind === 'backend' ? theme.spacing(1) : 0,
            }}
          >
            {kind}
          </Typography>
        )}

        {/* All data display when no filters applied */}
        {filters.length === 0 && segmentFilters.length === 0 && (
          <Box
            display='flex'
            alignItems='center'
            height={30}
            sx={{
              backgroundColor: '#fff',
              alignItems: 'center',
              border: '1px solid #dbdbdb',
              borderRadius: theme => theme.shape.borderRadius / 4,
              padding: '6px 8px 6px 8px',
            }}
          >
            <Typography variant='body1' sx={{ fontSize: 13, lineHeight: '13px', color: theme => theme.palette.text.secondary }}>
              {getAllDataDisplayByRowType(kind)}
            </Typography>
          </Box>
        )}

        {/* Segment filters */}
        {segmentFilters.length > 0 && (
          <>
            {segmentFilters.map(segmentFilter =>
              <ContextChipFilter
                key={segmentFilter.id}
                savedSegments={savedSegments}
                config={config}
                segmentId={segment.id}
                type='segmentFilter'
                kind={kind}
                segmentFilter={segmentFilter}
                onSegmentFilterDelete={onSegmentFilterDelete}
              />
            )}
          </>
        )}

        {/* Filters */}
        {filters.length > 0 && (
          <>
            {filters.map((filter, filterIndex) =>
              <ContextChipFilter
                key={filterIndex}
                savedSegments={savedSegments}
                savedGroups={savedGroups}
                config={config}
                segmentId={segment.id}
                segment={segment}
                type='filter'
                kind={kind}
                filter={filter}
                filterIndex={filterIndex}
                onSegmentFilterAdd={onSegmentFilterAdd}
                onFilterUpdate={onFilterUpdate}
                onFilterDelete={onFilterDelete}
              />
            )}
          </>
        )}

        {/* Filter actions */}
        <Box display='flex' flexDirection='row' alignItems='center' flexGrow={1}>
          <AddFilterPopover
            savedGroups={savedGroups}
            savedSegments={savedSegments}
            segment={segment}
            disableSaleFilter={(kind === 'frontend' || kind === 'all' ) && config.attribution === 'lead'}
            onFilterAdd={onFilterAdd}
            onSegmentFilterAdd={onSegmentFilterAdd}
            kind={kind}
          />

          {/* Breakdown select, only show if breakdown is allowed and not already applied */}
          {allowBreakdown && !segment.breakdown && (
            <BreakdownSelect
              segmentId={segment.id}
              kind={kind}
              fieldType={fieldType}
              breakdownLabel={'Breakdown'}
              onSegmentBreakdownSelect={onSegmentBreakdownSelect}
              includeCohortFields={allowCohorts}
            />
          )}

          {(filters.length > 0 || segmentFilters.length > 0) && (
            <>
              <Button
                variant='text'
                size='small'
                onClick={() => onClearAllSegmentFilters(segment.id, kind)}
                sx={{
                  color: theme => theme.palette.error.main,
                  '&:hover': {
                    background: 'rgba(255,23,68,0.04)',
                  }
                }}
              >
                Clear
              </Button>

              {/* Create Segment Dialog */}
              <Dialog open={segmentCreateDialogOpen} onClose={handleSegmentCreateDialogClose} maxWidth='xs' fullWidth>
                <DialogTitle>Create New Segment</DialogTitle>
                <DialogContent
                  sx={{ padding: theme => theme.spacing(1, 3, 0, 3)}}
                >
                  <TextField
                    autoFocus
                    size='small'
                    margin='dense'
                    label='Segment Name'
                    type='text'
                    fullWidth
                    value={segmentName}
                    onChange={e => setSegmentName(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button variant='text' onClick={handleSegmentCreateDialogClose} color='error'>
                    Cancel
                  </Button>
                  <Button variant='text' disabled={segmentName.length === 0} onClick={() => handleSegmentCreate(segment.id)} color='secondary'>
                    Create
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}

          <div style={{ flexGrow: 1 }} />

          {/* Segment actions, hide for backend kind to avoid duplicating these controls */}
          {kind !== 'backend' && (
            <React.Fragment>
              {segment.savedSegmentEditId ? (
                <>
                  <DarkTooltip
                    title='Save changes to segment'
                    placement='bottom'
                    arrow
                  >
                    <span>
                      <Button
                        variant='text'
                        size='small'
                        color='secondary'
                        onClick={() => onSegmentSaveEdit(segment.id)}
                      >
                        Save changes
                      </Button>
                    </span>
                  </DarkTooltip>
                  <DarkTooltip
                    title='Revert segment to previous state'
                    placement='bottom'
                    arrow
                  >
                    <span>
                      <Button
                        variant='text'
                        size='small'
                        color='error'
                        onClick={() => onSegmentRevertEdit(segment.id)}
                      >
                        Revert
                      </Button>
                    </span>
                  </DarkTooltip>
                </>
              ) : (
                segmentFilters.length === 1 ? (
                  <DarkTooltip
                    title='Edit saved segment'
                    placement='bottom'
                    arrow
                  >
                    <span>
                      <Button
                        variant='text'
                        size='small'
                        color='secondary'
                        onClick={() => onSegmentEditChange(segment.id, segmentFilters[0].id)}
                      >
                        Edit Segment
                      </Button>
                    </span>
                  </DarkTooltip>
                ) : (
                  <DarkTooltip
                    title={segmentFilters.length > 0 ?
                    'Cannot save a segment that contains multiple segments'
                    : (filters.length === 0 ?
                      'Add filters to save segment': 'Save filters to a segment'
                    )}
                    placement='bottom'
                    arrow
                  >
                    <span>
                      <Button
                        variant='text'
                        size='small'
                        color='secondary'
                        onClick={handleSegmentCreateDialogOpen}
                        disabled={segmentFilters.length > 0 || filters.length === 0}
                      >
                        Save Segment
                      </Button>
                    </span>
                  </DarkTooltip>
                )
              )}

              {/* Duplicate button */}
              {!allowOneSegmentOnly && (
                <DarkTooltip
                  title='Duplicate segment'
                  placement='bottom'
                  arrow
                >
                  <IconButton
                    size='small'
                    onClick={() => onSegmentDuplicate(segment.id)}
                  >
                    <ContentCopyIcon fontSize='inherit' />
                  </IconButton>
                </DarkTooltip>
              )}

              {/* Only show remove segment if there are multiple segments. There must always be at least one segment */}
              {segments.length > 1 ? (
                <>
                  <DarkTooltip
                    title='Remove segment'
                    placement='bottom'
                    arrow
                  >
                    <IconButton
                      size='small'
                      onClick={() => onSegmentDelete(segment.id)}
                    >
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  </DarkTooltip>

                  <DarkTooltip
                    title='Reorder segment'
                    placement='bottom'
                    arrow
                  >
                    <IconButton {...dragHandleProps} size='small'>
                      <DragHandleIcon fontSize='inherit' />
                    </IconButton>
                  </DarkTooltip>
                </>
              ) : (
                <div {...dragHandleProps} />
              )}
            </React.Fragment>
          )}
        </Box>
      </Box>

      {/* Breakdown controls row, only visible when a breakdown is applied */}
      {segment.breakdown && segment.breakdown.kind === kind && (
        <Box
          display='inline-flex'
          marginLeft={kind !== 'all' ? 2 : 0}
        >
          <svg width={20} height={30}>
            <line x1={6} y1={0} x2={6} y2={15} stroke={theme.palette.text.secondary} />
            <line x1={6} y1={15} x2={20} y2={15} stroke={theme.palette.text.secondary} />
          </svg>
          <Box width='100%' display='flex' flexDirection='row' alignItems='center' flexWrap='wrap' rowGap={0} columnGap={1} marginLeft={1}>
            <Box
              display='flex'
              alignItems='center'
              sx={{
                backgroundColor: '#fff',
                alignItems: 'center',
                border: '1px solid #dbdbdb',
                borderRadius: theme => theme.shape.borderRadius / 4,
                padding: '6px 8px 6px 8px',
              }}
            >
              <DarkTooltip title={`Breakdown segment by ${breakdownName}`} arrow>
                <Box display='flex' flexDirection='row' alignItems='center' columnGap={0.5}>
                  <TableRowsIcon style={{ fontSize: 13 }} />
                  <Typography
                    variant='body1'
                    sx={{
                      fontSize: 13,
                      lineHeight: '13px',
                      color: theme => theme.palette.text.primary,
                      fontWeight: 600,
                      flexGrow: 1,
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      cursor: 'default',
                    }}
                  >
                    {breakdownName}
                  </Typography>
                  {disableBreakdownBasedOnCohortAllowance(segment.breakdown, allowCohorts) ? (
                    <Typography variant='body1' sx={{ fontSize: 13, lineHeight: '13px', color: theme => theme.palette.text.secondary, flexShrink: 0 }}>
                      (disabled for this report)
                    </Typography>
                  ) : (
                    <Typography variant='body1' sx={{ fontSize: 13, lineHeight: '13px', color: theme => theme.palette.text.secondary, flexShrink: 0 }}>
                      ({activeBreakdownKeys.length} active)
                    </Typography>
                  )}
                </Box>
              </DarkTooltip>
            </Box>

            {/* Active Breakdowns */}
            {activeBreakdownKeys.map(key => (
              <ContextChipBreakdown
                key={key}
                segment={segment}
                breakdownKey={key}
                aliases={aliases}
                onSegmentBreakdownColorChange={onSegmentBreakdownColorChange}
              />
            ))}

            <BreakdownSelect
              segmentId={segment.id}
              kind={kind}
              fieldType={fieldType}
              breakdownLabel={'Change'}
              onSegmentBreakdownSelect={onSegmentBreakdownSelect}
              includeCohortFields={allowCohorts}
            />
            <Button
              variant='text'
              size='small'
              onClick={() => onSegmentBreakdownSelect(segment.id, null)}
              sx={{
                color: theme => theme.palette.error.main,
                '&:hover': {
                  background: 'rgba(255,23,68,0.04)',
                }
              }}
            >
              Remove
            </Button>
          </Box>


        </Box>
      )}
    </Box>
  )
}

SegmentBuilder.propTypes = {
  config: PropTypes.object.isRequired,
  splitSegmentFrontBack: PropTypes.bool,
  allowBreakdown: PropTypes.bool,
  allowCohorts: PropTypes.bool,
  allowOneSegmentOnly: PropTypes.bool,
  segment: PropTypes.object.isRequired,
  segmentEligibleBreakdownKeys: PropTypes.array.isRequired,
  segments: PropTypes.array.isRequired,
  aliases: PropTypes.object.isRequired,
  savedSegments: PropTypes.array.isRequired,
  savedGroups: PropTypes.array.isRequired,
  onSegmentDelete: PropTypes.func.isRequired,
  onSegmentDuplicate: PropTypes.func.isRequired,
  onSegmentEditChange: PropTypes.func.isRequired,
  onSegmentSaveEdit: PropTypes.func.isRequired,
  onSegmentRevertEdit: PropTypes.func.isRequired,
  onSegmentColorChange: PropTypes.func.isRequired,
  onSegmentFilterAdd: PropTypes.func.isRequired,
  onSegmentReplaceFiltersWithSavedSegmentFilter: PropTypes.func.isRequired,
  onSegmentFilterDelete: PropTypes.func.isRequired,
  onFilterAdd: PropTypes.func.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  onClearAllSegmentFilters: PropTypes.func.isRequired,
  onSegmentBreakdownSelect: PropTypes.func.isRequired,
  onSegmentBreakdownColorChange: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.object.isRequired,
}

export default SegmentBuilder