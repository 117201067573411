import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import {
  QueryClient,
  QueryClientProvider
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { FirebaseContext } from '../../utils/firebase'
import 'firebase/auth'

import { readDocumentWithoutUidFilter } from '../../utils/firestore'
import { FIRESTORE_COLLECTIONS } from '../../constants'

import { ColorThemeContext } from '../../contexts/ColorThemeContext'
import { UserContext } from '../../contexts/UserContext'

import PrivateNavRoute from './PrivateNavRoute'
import Admin from '../Admin'
import Analysis from '../Analysis'
import Contacts from '../People/Contacts'
import Customers from '../People/Customers'
import Daily from '../Daily'
import Dashboard from '../Dashboard'
import Demo from '../Login/Demo'
import Login from '../Login'
import Payments from '../People/Payments'
import PasswordForgot from '../PasswordForgot'
import PasswordResetSent from '../PasswordForgot/PasswordResetSent'
import PathAnalysis from '../Analysis/PathAnalysis'
import Person from '../People/Person'
import Profile from '../Settings/Profile'
import Reports from '../Reports/Reports'
import Scorecards from '../Reports/Scorecards'
import Settings from '../Settings'
import Targets from '../Reports/Targets'
import Upload from '../Upload'
import BaskHealthSource from '../Settings/BaskHealthSource'
import BuygoodsSource from '../Settings/BuygoodsSource'
import ClickbankSource from '../Settings/ClickbankSource'
import ClickfunnelsSource from '../Settings/ClickfunnelsSource'
import CommittedSource from '../Settings/CommittedSource'
import CustomSource from '../Settings/CustomSource'
import Digistore24Source from '../Settings/Digistore24Source'
import EasyWebinarSource from '../Settings/EasyWebinarSource'
import ElementorSource from '../Settings/ElementorSource'
import HighlevelSource from '../Settings/HighlevelSource'
import KajabiSource from '../Settings/KajabiSource'
import KeapSource from '../Settings/KeapSource'
import KeapOauth from '../Settings/KeapOauth'
import KitSource from '../Settings/KitSource'
import KonnektiveSource from '../Settings/KonnektiveSource'
import MaropostSource from '../Settings/MaropostSource'
import OntraportSource from '../Settings/OntraportSource'
import PaypalSource from '../Settings/PaypalSource'
import SamcartSource from '../Settings/SamcartSource'
import ShopifyAppInstall from '../Settings/ShopifyAppInstall'
import ShopifySource from '../Settings/ShopifySource'
import ShopifyOauth from '../Settings/ShopifyOauth'
import StickySource from '../Settings/StickySource'
import StripeSource from '../Settings/StripeSource'
import StripeOauth from '../Settings/StripeOauth'
import UltracartSource from '../Settings/UltracartSource'
import WoocommerceSource from '../Settings/WoocommerceSource'

import HyrosEnhancer from '../Settings/HyrosEnhancer'
import RechargeEnhancer from '../Settings/RechargeEnhancer'

import FullPageLoadingSpinner from '../Common/FullPageLoadingSpinner'

import * as ROUTES from '../../constants/routes'

const queryClient = new QueryClient()

const App = () => {
  const firebase = React.useContext(FirebaseContext)
  const { setPrimary, setSecondary } = React.useContext(ColorThemeContext)

  const [currentUser, setCurrentUser] = React.useState(null)
  const [loadingUserDoc, setLoadingUserDoc] = React.useState(true)
  const [userDoc, setUserDoc] = React.useState({})

  // Listen for Firebase auth state changes to update currentUser
  React.useEffect(() => {
    return firebase.auth().onAuthStateChanged(user => {
      setCurrentUser(user)
    })
  }, [firebase])

  // Fetch user document from Firestore, and listen for updates to update UserContext
  React.useEffect(() => {
    if (!currentUser) return

    const fetchAndSetTheme = async () => {
      const doc = await readDocumentWithoutUidFilter(firebase, FIRESTORE_COLLECTIONS.USERS, currentUser.uid)
      setUserDoc(doc)

      // Set theme colors in the ColorThemeContext
      if (doc.primaryColor) setPrimary(doc.primaryColor)
      if (doc.secondaryColor) setSecondary(doc.secondaryColor)

      setLoadingUserDoc(false)
    }

    fetchAndSetTheme()

    // set up a Firestore listener
    const unsubscribe = firebase.firestore().collection(FIRESTORE_COLLECTIONS.USERS).doc(currentUser.uid)
      .onSnapshot(doc => {
        setUserDoc({ id: doc.id, ...doc.data() }) // update the userDoc when the document changes
      })

    // clean up the listener when the component unmounts or the currentUser changes
    return unsubscribe
  }, [currentUser])

  if (currentUser && loadingUserDoc) {
    return <FullPageLoadingSpinner />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <UserContext.Provider value={userDoc}>
        <Router>
          <Switch>
            <PrivateNavRoute exact path={ROUTES.ADMIN} component={Admin} />
            <PrivateNavRoute exact path={ROUTES.DAILY} component={Daily} />
            <PrivateNavRoute exact path={ROUTES.DASHBOARD} component={Dashboard} />
            <PrivateNavRoute exact path={ROUTES.ANALYSIS} component={Analysis} />

            <Route exact path={ROUTES.REPORTS}>
              <Redirect to={ROUTES.REPORTS_DEFAULT} />
            </Route>
            <PrivateNavRoute path={ROUTES.REPORTS} component={Reports} />

            <PrivateNavRoute path={ROUTES.SCORECARDS} component={Scorecards} />

            <Route exact path={ROUTES.TARGETS}>
              <Redirect to={ROUTES.TARGETS_DEFAULT} />
            </Route>
            <PrivateNavRoute path={ROUTES.TARGETS} component={Targets} />

            <PrivateNavRoute exact path={ROUTES.PATH_ANALYSIS} component={PathAnalysis} />
            <Route exact path={ROUTES.PEOPLE}>
              <Redirect to={ROUTES.CUSTOMERS} />
            </Route>
            <PrivateNavRoute exact path={ROUTES.CONTACTS} component={Contacts} />
            <PrivateNavRoute exact path={ROUTES.CUSTOMERS} component={Customers} />
            <PrivateNavRoute exact path={ROUTES.PAYMENTS} component={Payments} />
            <PrivateNavRoute exact path={ROUTES.PEOPLE + '/:id'} component={Person} />
            <PrivateNavRoute exact path={ROUTES.UPLOAD} component={Upload} />
            <PrivateNavRoute exact path={ROUTES.PROFILE} component={Profile} />
            <PrivateNavRoute exact path={ROUTES.SETTINGS} component={Settings} />
            <PrivateNavRoute exact path={ROUTES.BASKHEALTH_SOURCES + '/:id'} component={BaskHealthSource} />
            <PrivateNavRoute exact path={ROUTES.BUYGOODS_SOURCES + '/:id'} component={BuygoodsSource} />
            <PrivateNavRoute exact path={ROUTES.CLICKBANK_SOURCES + '/:id'} component={ClickbankSource} />
            <PrivateNavRoute exact path={ROUTES.CLICKFUNNELS_SOURCES + '/:id'} component={ClickfunnelsSource} />
            <PrivateNavRoute exact path={ROUTES.COMMITTED_SOURCES + '/:id'} component={CommittedSource} />
            <PrivateNavRoute exact path={ROUTES.CUSTOM_SOURCES + '/:id'} component={CustomSource} />
            <PrivateNavRoute exact path={ROUTES.DIGISTORE24_SOURCES + '/:id'} component={Digistore24Source} />
            <PrivateNavRoute exact path={ROUTES.EASYWEBINAR_SOURCES + '/:id'} component={EasyWebinarSource} />
            <PrivateNavRoute exact path={ROUTES.ELEMENTOR_SOURCES + '/:id'} component={ElementorSource} />
            <PrivateNavRoute exact path={ROUTES.HIGHLEVEL_SOURCES + '/:id'} component={HighlevelSource} />
            <PrivateNavRoute exact path={ROUTES.KAJABI_SOURCES + '/:id'} component={KajabiSource} />
            <PrivateNavRoute exact path={ROUTES.KEAP_OAUTH} component={KeapOauth} />
            <PrivateNavRoute exact path={ROUTES.KEAP_SOURCES + '/:id'} component={KeapSource} />
            <PrivateNavRoute exact path={ROUTES.KIT_SOURCES + '/:id'} component={KitSource} />
            <PrivateNavRoute exact path={ROUTES.KONNEKTIVE_SOURCES + '/:id'} component={KonnektiveSource} />
            <PrivateNavRoute exact path={ROUTES.MAROPOST_SOURCES + '/:id'} component={MaropostSource} />
            <PrivateNavRoute exact path={ROUTES.ONTRAPORT_SOURCES + '/:id'} component={OntraportSource} />
            <PrivateNavRoute exact path={ROUTES.PAYPAL_SOURCES + '/:id'} component={PaypalSource} />
            <PrivateNavRoute exact path={ROUTES.SAMCART_SOURCES + '/:id'} component={SamcartSource} />
            <Route exact path={ROUTES.SHOPIFY_APP_INSTALL} component={ShopifyAppInstall} />
            <PrivateNavRoute exact path={ROUTES.SHOPIFY_OAUTH} component={ShopifyOauth} />
            <PrivateNavRoute exact path={ROUTES.SHOPIFY_SOURCES + '/:id'} component={ShopifySource} />
            <PrivateNavRoute exact path={ROUTES.STICKY_SOURCES + '/:id'} component={StickySource} />
            <PrivateNavRoute exact path={ROUTES.STRIPE_OAUTH} component={StripeOauth} />
            <PrivateNavRoute exact path={ROUTES.STRIPE_SOURCES + '/:id'} component={StripeSource} />
            <PrivateNavRoute exact path={ROUTES.WOOCOMMERCE_SOURCES + '/:id'} component={WoocommerceSource} />
            <PrivateNavRoute exact path={ROUTES.ULTRACART_SOURCES + '/:id'} component={UltracartSource} />

            <PrivateNavRoute exact path={ROUTES.HYROS_ENHANCERS + '/:id'} component={HyrosEnhancer} />
            <PrivateNavRoute exact path={ROUTES.RECHARGE_ENHANCERS + '/:id'} component={RechargeEnhancer} />

            <Route exact path={ROUTES.HOME}>
              <Redirect to={ROUTES.REPORTS} />
            </Route>

            <Route path={ROUTES.LOGIN} component={Login} />
            <Route path={ROUTES.PASSWORD_FORGOT} component={PasswordForgot} />
            <Route path={ROUTES.PASSWORD_RESET_SENT} component={PasswordResetSent} />
            <Route exact path={ROUTES.DEMO} component={Demo} />
          </Switch>
        </Router>
      </UserContext.Provider>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen position='bottom-right' />}
    </QueryClientProvider>
  )
}

export default App
