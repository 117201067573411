import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import DownloadIcon from '@mui/icons-material/Download'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TableRowsIcon from '@mui/icons-material/TableRows'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { lighten } from '@mui/material/styles'
import { useTheme } from '@mui/styles'
import moment from 'moment-timezone'
import FileSaver from 'file-saver'
import { useCurrentPng } from 'recharts-to-png'
import { useIsFetching } from 'react-query'

import { UserContext } from '../../contexts/UserContext'

import {
  ResponsiveContainer,
  ComposedChart,
  Area,
  Bar,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
} from 'recharts'

import ChartDisplayButtonGroup from './ChartDisplayButtonGroup'
import ChartResolutionButtonGroup from './ChartResolutionButtonGroup'
import DarkTooltip from './DarkTooltip'
import LoadingSkeleton from './LoadingSkeleton'
import NoDataBox from './NoDataBox'
import SquareIconColored from './SquareIconColored'

import {
  getBreakdownAlias,
  getChartData,
  getCohortBreakdownResolution,
  getDisplayValueByFormat,
  getXKeyStartValueFromDataPoint,
  getSegmentBreakdownComboKey,
  isCohortBreakdown,
  shortenString,
  transformChartDataToPercentages
} from '../../utils/helpers'

const CHART_HEIGHT = 300
const MAX_DOT_POINTS = 50
const TICK_FONT_SIZE = 12
const TIMEZONE = moment.tz.guess() // This should be set by user configuration

const Chart = ({
  xKey,
  xKeyAsRange,
  emptyDataRule,
  allowSecondaryChart,
  allowLineDots,
  allowUseStack100,
  hideYValuesAfterToday,
  metricItem,
  endDate,
  resolution,
  config,
  showCumulative,
  showSecondaryChart,
  segments,
  segmentsData,
  segmentsBreakdownData,
  segmentsEligibleBreakdownKeys,
  aliases,
  chartHoverIndex,
  referenceLines,
  onResolutionChange,
  onShowCumulativeChange,
  onShowSecondaryChartChange,
  onChartHoverIndexChange
}) => {
  const userDoc = React.useContext(UserContext)

  const fetchingQueryCount = useIsFetching()

  const [chartDisplay, setChartDisplay] = React.useState('line')
  const [useStack, setUseStack] = React.useState(false)
  const [useStack100, setUseStack100] = React.useState(false)
  const [useZeroYAxis, setUseZeroYAxis] = React.useState(false)
  const [isAnimationActive, setIsAnimationActive] = React.useState(false)
  const [isHovering, setIsHovering] = React.useState(false)
  const [getChartPng, { ref: chartRef }] = useCurrentPng()
  const chartRefSecondary = useRef()

  const handleOnMouseMove = (event) => {
    if (isAnimationActive) return
    setIsHovering(true)
    if (event && event.activeTooltipIndex !== undefined) {
      onChartHoverIndexChange(event.activeTooltipIndex)
    }
  }

  const handleOnMouseLeave = () => {
    if (isAnimationActive) return
    setIsHovering(false)
    onChartHoverIndexChange(null)
  }

  const handleAnimationStart = (duration) => {
    setIsAnimationActive(true)
    setTimeout(() => {
      setIsAnimationActive(false)
    }, duration)
  }

  const handleChartPngDownload = useCallback(async () => {
    const png = await getChartPng()
    if (png) {
      const filename = `ltvnumbers_chart_${metricItem.key}_${moment.tz(TIMEZONE).format('YYYY-MM-DD_HH-mm-ss')}.png`
      FileSaver.saveAs(png, filename)
    }
  }, [getChartPng])

  const handleTooltipDisplay = (chartRef, chartHoverIndex) => {
    // Hide tooltip if nothing is hovered
    if (chartHoverIndex === null) {
      if (chartRef.current) {
        chartRef.current.setState({
          isTooltipActive: false,
        })
      }
    }
    // Find the appropriate item and show tooltip for it
    else if (chartHoverIndex !== null && !isHovering) {
      if (!chartRef.current) return

      let activeItem
      if (chartDisplay === 'line') {
        activeItem = chartRef.current.state.formattedGraphicalItems?.[0].props.points?.[chartHoverIndex]
      } else if (chartDisplay === 'bar') {
        activeItem = chartRef.current.state.formattedGraphicalItems?.[0].props.data?.[chartHoverIndex]
      } else if (chartDisplay === 'area') {
        activeItem = chartRef.current.state.formattedGraphicalItems?.[0].props.points?.[chartHoverIndex]
      } else {
        throw new Error('unrecognized chartDisplay: ' + chartDisplay)
      }
      if (!activeItem) return

      chartRef.current.setState({
        activeTooltipIndex: chartHoverIndex,
        activeLabel: activeItem.payload[xKey],
      }, () => {
        chartRef.current.handleItemMouseEnter({
          // The reverse is done to visually match the stack order to the order segments are displayed elsewhere
          tooltipPayload: (useStack ? [...readyChartItems].reverse() : readyChartItems).map(item => ({
            ...activeItem,
            dataKey: item.id,
            name: item.name,
            color: item.color,
            value: activeItem.payload[item.id],
          })),
          tooltipPosition: {
            x: activeItem.x,
            y: activeItem.y,
          }
        })
      })
    }
  }

  const allSegmentsAreLoading = segments.every((segment, segmentIndex) => segmentsData[segmentIndex].isLoading)

  const metricItemSecondary = useMemo(() => {
    return metricItem.secondary ? metricItem.secondary : null
  }, [metricItem])

  const readyChartItems = useMemo(() => {
    let readyChartItems = []
    segments.forEach((segment, segmentIndex) => {
      // If segment is not loading and is active, add it
      if (!segmentsData[segmentIndex].isLoading && segment.isActive) {
        readyChartItems.push({
          id: segment.id,
          name: segment.name,
          color: segment.color,
        })
      }

      // If segment has a breakdown, add all active breakdowns
      if (segment.breakdown) {
        Object.keys(segment.breakdownState).forEach(breakdownKey => {
          const stateObj = segment.breakdownState[breakdownKey]

          // Check that breakdown is active and eligible (meaning it is included in the API response data)
          const isEligible = segmentsEligibleBreakdownKeys[segmentIndex].includes(breakdownKey)
          const isActive = stateObj.isActive
          if (isEligible && isActive) {
            if (isCohortBreakdown(segment.breakdown)) {
              const breakdownResolution = getCohortBreakdownResolution(segment.breakdown)
              readyChartItems.push({
                id: getSegmentBreakdownComboKey(segment.id, breakdownKey),
                name: getDisplayValueByFormat(
                  breakdownKey,
                  'date_time',
                  {
                    resolution: breakdownResolution,
                    timezone: TIMEZONE
                  }
                ),
                color: stateObj.color,
              })
            } else {
              readyChartItems.push({
                id: getSegmentBreakdownComboKey(segment.id, breakdownKey),
                name: getBreakdownAlias(aliases, segment.breakdown.field, breakdownKey),
                color: stateObj.color,
              })
            }
          }
        })
      }
    })

    return readyChartItems
  }, [segments, segmentsData, aliases])

  let chartData = useMemo(() => {
    const cumulative = showCumulative && metricItem.allowCumulative
    let chartData = getChartData(segments, segmentsData, segmentsBreakdownData, xKey, metricItem.key, endDate, TIMEZONE, config, true, emptyDataRule, cumulative)
    if (useStack100) {
      chartData = transformChartDataToPercentages(chartData, xKey)
    }

    // If a target is provided, hide yValues after today
    if (hideYValuesAfterToday) {
      const xValueCutoff = moment.tz(TIMEZONE).startOf('day')
      chartData = chartData.map(dataPoint => {
        if (moment.tz(dataPoint[xKey], TIMEZONE).isAfter(xValueCutoff)) {
          return { [xKey]: dataPoint[xKey] }
        } else {
          return dataPoint
        }
      })
    }

    return chartData
  }, [xKey, segments, segmentsData, segmentsBreakdownData, endDate, config, metricItem, useStack100, showCumulative, emptyDataRule])

  const chartDataSecondary = useMemo(() => {
    // If the config does not allow a secondary chart, return nothing
    if (!allowSecondaryChart) return null

    // If the selected metric doesn't have a secondary metric, return nothing
    if (!metricItemSecondary) return null

    const cumulative = showCumulative && metricItem.allowCumulative && metricItemSecondary.allowCumulative
    let chartData = getChartData(segments, segmentsData, segmentsBreakdownData, xKey, metricItemSecondary.key, endDate, TIMEZONE, config, true, emptyDataRule, cumulative)
    if (useStack100) {
      chartData = transformChartDataToPercentages(chartData, xKey)
    }
    return chartData
  }, [xKey, segments, segmentsData, segmentsBreakdownData, endDate, config, metricItem, metricItemSecondary, useStack100, showCumulative, emptyDataRule])

  const showLineDots = useMemo(() => {
    return allowLineDots && chartData && chartData.length <= MAX_DOT_POINTS
  }, [chartData, allowLineDots])

  // Listen for changes to chartHoverIndex from outside this component
  React.useEffect(() => {
    let isMounted = true

    if (isMounted) {
      handleTooltipDisplay(chartRef, chartHoverIndex)
      if (showSecondaryChart) {
        handleTooltipDisplay(chartRefSecondary, chartHoverIndex)
      }
    }

    return () => {
      isMounted = false
    }
  }, [chartHoverIndex, showSecondaryChart])

  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.background.paper,
      }}
    >
      {fetchingQueryCount > 0 && (
        <Box position='relative'>
          <LinearProgress
            variant='indeterminate'
            color='primary'
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              width: '100%',
              height: 4,
              zIndex: 1000,
              borderTopRightRadius: theme => theme.shape.borderRadius,
              borderTopLeftRadius: theme => theme.shape.borderRadius,
            }}
          />
        </Box>
      )}
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-start'
        columnGap={1}
        paddingX={2}
        paddingTop={2}
        paddingBottom={1}
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          sx={{
            flexGrow: 1,
            flexBasis: 'auto',
            flexShrink: 1
          }}
        >
          <Typography
            variant='body1'
            color='textPrimary'
            sx={{
              lineHeight: 1.1,
            }}
          >
            {showCumulative && metricItem.allowCumulative && metricItem.cumulativeName ? metricItem.cumulativeName : metricItem.name}
            {showCumulative && metricItem.allowCumulative ? ' (cumulative)' : ''}
          </Typography>
          {metricItemSecondary && allowSecondaryChart && (
            <DarkTooltip
              title={`${showSecondaryChart ? 'Hide' : 'Show'} secondary chart`}
              placement='bottom'
              arrow
            >
              <IconButton
                onClick={() => onShowSecondaryChartChange(!showSecondaryChart)}
              >
                {showSecondaryChart ?
                  <KeyboardArrowUpIcon /> :
                  <KeyboardArrowDownIcon />}
              </IconButton>
            </DarkTooltip>
          )}
        </Box>

        <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
          <ChartDisplayButtonGroup
            allowCumulative={metricItem.allowCumulative}
            allowUseStack100={allowUseStack100}
            showCumulative={showCumulative}
            onShowCumulativeChange={onShowCumulativeChange}
            chartDisplay={chartDisplay}
            onChartDisplayChange={setChartDisplay}
            useStack={useStack}
            onUseStackChange={setUseStack}
            useStack100={useStack100}
            onUseStack100Change={setUseStack100}
            useZeroYAxis={useZeroYAxis}
            onUseZeroYAxisChange={setUseZeroYAxis}
          />
        </Box>

        <Box display='flex' flexDirection='row' sx={{ flexGrow: 0, flexShrink: 0 }}>
          <ChartResolutionButtonGroup
            xKey={xKey}
            resolution={resolution}
            onResolutionChange={onResolutionChange}
          />
          <IconButton
            onClick={handleChartPngDownload}
          >
            <DownloadIcon />
          </IconButton>
        </Box>
      </Box>

      {allSegmentsAreLoading ? (
        <Box height={CHART_HEIGHT} paddingX={2} paddingBottom={2}>
          <LoadingSkeleton height={'100%'} width={'100%'} />
        </Box>
      ) : readyChartItems.length === 0 ? (
        <Box width='100%' height={CHART_HEIGHT} display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
          <Typography variant='h6' color='textSecondary'>
            No active segments. Turn on segments using the switches in the table below.
          </Typography>
        </Box>
      ) : chartData.length == 0 ? (
        <NoDataBox height={CHART_HEIGHT} />
      ) : (
        <Box width='100%' position='relative' paddingX={1}>
          <ResponsiveContainer width='100%' height={showSecondaryChart ? CHART_HEIGHT * 2 / 3 : CHART_HEIGHT}>
            <ComposedChart
              ref={chartRef}
              syncId={metricItem.key}
              data={chartData}
              margin={{ top: 10, bottom: 0, left: 0, right: 20 }}
              onMouseMove={handleOnMouseMove}
              onMouseLeave={handleOnMouseLeave}
            >
              <XAxis
                dataKey={xKey}
                interval={'equidistantPreserveStart'}
                tick={{ fontSize: TICK_FONT_SIZE }}
                tickFormatter={val => {
                  if (val === 'auto') return
                  const dataPoint = chartData.find(dataPoint => dataPoint[xKey] === val)
                  const xKeyStartValue = getXKeyStartValueFromDataPoint(xKey, dataPoint)
                  return getDisplayValueByFormat(val, xKey, { resolution, timezone: TIMEZONE, xKeyAsRange }, xKeyStartValue)
                }}
              />
              <YAxis
                type='number'
                domain={useZeroYAxis ? [0, 'auto'] : (useStack100 ? [0, 1] : ['auto', 'auto'])}
                tick={{ fontSize: TICK_FONT_SIZE }}
                tickFormatter={val =>
                  getDisplayValueByFormat(
                    val,
                    useStack100 ? 'percent' : metricItem.format,
                    {
                      resolution,
                      timezone: TIMEZONE,
                      decimalCount: 2,
                      hideIntegerDecimals: true,
                      abbreviateLargeAmounts: true,
                      currency: userDoc.currency,
                    }
                  )}
              />
              <CartesianGrid strokeDasharray='1 1' vertical={false} />
              <Tooltip
                isAnimationActive={false}
                content={
                  <CustomTooltip
                    chartDisplay={chartDisplay}
                    useStack={useStack}
                    useStack100={useStack100}
                    xKey={xKey}
                    xKeyAsRange={xKeyAsRange}
                    metricItem={metricItem}
                    resolution={resolution}
                    userDoc={userDoc}
                  />
                }
                wrapperStyle={{ zIndex: 1000 }}
              />
              {/* The reverse is done to visually match the stack order to the order segments are displayed elsewhere */}
              {(useStack ? [...readyChartItems].reverse() : readyChartItems).map(item => {
                switch(chartDisplay) {
                  case 'line': {
                    if (useStack) {
                      return (
                        <Area
                          key={item.id}
                          dataKey={item.id}
                          name={item.name}
                          type='linear'
                          stroke={item.color}
                          fill={item.color}
                          fillOpacity={1}
                          stackId='area'
                          animationDuration={1000}
                          onAnimationStart={() => handleAnimationStart(1000)}
                        />
                      )
                    } else {
                      return (
                        <Line
                          key={item.id}
                          dataKey={item.id}
                          name={item.name}
                          stroke={item.color}
                          strokeWidth={3}
                          dot={showLineDots ? { r: 2, fill: item.color } : false}
                          type='linear'
                          animationDuration={750}
                          onAnimationStart={() => handleAnimationStart(750)}
                          animationEasing={'ease-out'}
                        />
                      )
                    }
                  }
                  case 'bar':
                    return (
                      <Bar
                        key={item.id}
                        dataKey={item.id}
                        name={item.name}
                        fill={item.color}
                        stackId={useStack ? 'bar' : undefined}
                        animationDuration={1000}
                        onAnimationStart={() => handleAnimationStart(1000)}
                      />
                    )
                  default:
                    return null
                }
              })}

              {/* Reference Lines */}
              {referenceLines && referenceLines.length > 0 &&
                referenceLines.map(referenceLine => {
                  switch(referenceLine.type) {
                    case 'x':
                      return (
                        <ReferenceLine
                          key={referenceLine.key}
                          id={referenceLine.key}
                          label={
                            <CustomReferenceLineLabel
                              type={referenceLine.type}
                              label={referenceLine.label}
                              stroke={referenceLine.stroke}
                            />
                          }
                          x={referenceLine.value}
                          stroke={referenceLine.stroke}
                          strokeWidth={referenceLine.strokeWidth}
                          strokeDasharray={referenceLine.strokeDasharray}
                          ifOverflow='extendDomain'
                          isFront
                        />
                      )
                    case 'y':
                      return (
                        <ReferenceLine
                          key={referenceLine.key}
                          id={referenceLine.key}
                          label={
                            <CustomReferenceLineLabel
                              type={referenceLine.type}
                              label={referenceLine.label}
                              stroke={referenceLine.stroke}
                            />
                          }
                          y={referenceLine.value}
                          stroke={referenceLine.stroke}
                          strokeWidth={referenceLine.strokeWidth}
                          strokeDasharray={referenceLine.strokeDasharray}
                          ifOverflow='extendDomain'
                          isFront
                        />
                      )
                    case 'segment':
                      return (
                        <ReferenceLine
                          key={referenceLine.key}
                          id={referenceLine.key}
                          label={
                            <CustomReferenceLineLabel
                              type={referenceLine.type}
                              label={referenceLine.label}
                              stroke={referenceLine.stroke}
                            />
                          }
                          stroke={referenceLine.stroke}
                          strokeWidth={referenceLine.strokeWidth}
                          strokeDasharray={referenceLine.strokeDasharray}
                          ifOverflow='extendDomain'
                          isFront
                          segment={[
                            {
                              x: referenceLine.value.xStart,
                              y: referenceLine.value.yStart,
                            },
                            {
                              x: referenceLine.value.xEnd,
                              y: referenceLine.value.yEnd,
                            }
                          ]}
                        />
                      )
                    default:
                      return null
                  }
                }
              )}
            </ComposedChart>
          </ResponsiveContainer>

          {showSecondaryChart && (
            <Box>
              <Box
                display='flex'
                flexDirection='row'
                columnGap={1}
                alignItems='center'
                paddingX={1}
                paddingBottom={1}
              >
                <Typography
                  variant='body1'
                  color='textSecondary'
                  sx={{
                    lineHeight: 1.1,
                  }}
                >
                  {showCumulative && metricItem.allowCumulative && metricItemSecondary?.cumulativeName ? metricItemSecondary.cumulativeName : metricItemSecondary.name}
                  {showCumulative && metricItem.allowCumulative && metricItemSecondary?.allowCumulative ? ' (cumulative)' : ''}
                  {showCumulative && metricItem.allowCumulative && !metricItemSecondary.allowCumulative ? ' (cumulative not available)' : ''}
                </Typography>
                <DarkTooltip
                  title='Hide secondary chart'
                  placement='bottom'
                  arrow
                >
                  <IconButton
                    size='small'
                    onClick={() => onShowSecondaryChartChange(false)}
                  >
                    <VisibilityOffIcon fontSize='inherit' />
                  </IconButton>
                </DarkTooltip>
              </Box>

              <ResponsiveContainer width='100%' height={CHART_HEIGHT / 2}>
                <ComposedChart
                  ref={chartRefSecondary}
                  syncId={metricItem.key}
                  data={chartDataSecondary}
                  margin={{ top: 5, bottom: 0, left: 0, right: 20 }}
                  onMouseMove={handleOnMouseMove}
                  onMouseLeave={handleOnMouseLeave}
                >
                  <XAxis
                    dataKey={xKey}
                    interval={'equidistantPreserveStart'}
                    tick={{ fontSize: TICK_FONT_SIZE }}
                    tickFormatter={val => {
                      const dataPoint = chartData.find(dataPoint => dataPoint[xKey] === val)
                      const xKeyStartValue = getXKeyStartValueFromDataPoint(xKey, dataPoint)
                      return getDisplayValueByFormat(val, xKey, { resolution, timezone: TIMEZONE, xKeyAsRange }, xKeyStartValue)
                    }}
                  />
                  <YAxis
                    type='number'
                    domain={useZeroYAxis ? [0, 'auto'] : (useStack100 ? [0, 1] : ['auto', 'auto'])}
                    tick={{ fontSize: TICK_FONT_SIZE }}
                    tickFormatter={val =>
                      getDisplayValueByFormat(
                        val,
                        useStack100 ? 'percent' : metricItemSecondary.format,
                        {
                          resolution,
                          timezone: TIMEZONE,
                          decimalCount: 2,
                          hideIntegerDecimals: true,
                          abbreviateLargeAmounts: true,
                          currency: userDoc.currency,
                        }
                      )}
                  />
                  <CartesianGrid strokeDasharray='1 1' vertical={false} />
                  <Tooltip
                    isAnimationActive={false}
                    content={
                      <CustomTooltip
                        chartDisplay={chartDisplay}
                        useStack={useStack}
                        useStack100={useStack100}
                        xKey={xKey}
                        xKeyAsRange={xKeyAsRange}
                        metricItem={metricItemSecondary}
                        resolution={resolution}
                        userDoc={userDoc}
                      />
                    }
                    wrapperStyle={{ zIndex: 1000 }}
                  />
                  {/* The reverse is done to visually match the stack order to the order segments are displayed elsewhere */}
                  {(useStack ? [...readyChartItems].reverse() : readyChartItems).map(item => {
                    switch(chartDisplay) {
                      case 'line': {
                        if (useStack) {
                          return (
                            <Area
                              key={item.id}
                              dataKey={item.id}
                              name={item.name}
                              type='linear'
                              stroke={item.color}
                              fill={item.color}
                              fillOpacity={1}
                              stackId='area'
                              animationDuration={1000}
                              onAnimationStart={() => handleAnimationStart(1000)}
                            />
                          )
                        } else {
                          return (
                            <Line
                              key={item.id}
                              dataKey={item.id}
                              name={item.name}
                              stroke={item.color}
                              strokeWidth={3}
                              dot={showLineDots ? { r: 2, fill: item.color } : false}
                              type='linear'
                              animationDuration={750}
                              onAnimationStart={() => handleAnimationStart(750)}
                              animationEasing={'ease-out'}
                            />
                          )
                        }
                      }
                      case 'bar':
                        return (
                          <Bar
                            key={item.id}
                            dataKey={item.id}
                            name={item.name}
                            fill={item.color}
                            stackId={useStack ? 'bar' : undefined}
                            animationDuration={1000}
                            onAnimationStart={() => handleAnimationStart(1000)}
                          />
                        )
                      default:
                        return null
                    }
                  })}
                </ComposedChart>
              </ResponsiveContainer>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

const CustomTooltip = ({ active, payload, chartDisplay, useStack, useStack100, xKey, xKeyAsRange, metricItem, resolution, userDoc }) => {
  if (payload === null) return null

  // Set tooltip sort order match appearance based on chartDisplay
  if (chartDisplay === 'line') {
    // If using stack, sort by stack order
    if (useStack) {
      payload.reverse()
    }
    // Otherwise, sort from biggest to smallest value
    else {
      payload.sort((a, b) => {
        return parseFloat(b.value) - parseFloat(a.value)
      })
    }
  }
  else if (chartDisplay === 'bar') {
    // If using stack, sort by stack order
    if (useStack) {
      payload.reverse()
    }
    // Otherwise the default sorting matches the bar order so don't change anything
  }

  if (active) {
    return (
      <Box
        sx={{
          backgroundColor: 'white',
          textAlign: 'left',
          padding: theme => theme.spacing(0),
          border: '1px solid rgb(204, 204, 204)',
          borderRadius: 1,
          minWidth: 150,
          maxWidth: 300,
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          columnGap={1}
          paddingX={1}
          paddingY={0.25}
          sx={{
            backgroundColor: '#DCE3EB',
            borderBottom: '1px solid #DCE3EB',
          }}
        >
          <Typography
            variant='caption'
            sx={{
              color: theme => theme.palette.text.secondary,
              flex: 1,
            }}
          >
            {metricItem?.name}
          </Typography>
          <Typography
            variant='caption'
            sx={{
              color: theme => theme.palette.text.primary,
              fontWeight: 600,
              whiteSpace: 'nowrap',
            }}
          >
            {getDisplayValueByFormat(payload[0]?.payload?.[xKey], xKey, { resolution, timezone: TIMEZONE, xKeyAsRange }, getXKeyStartValueFromDataPoint(xKey, payload[0]?.payload))}
          </Typography>
        </Box>
        <Box
          paddingX={1}
          paddingY={0.25}
        >
          {payload.map((datum, index) => {
            return (
              <React.Fragment key={datum.dataKey}>
                <Box key={datum.dataKey} display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' columnGap={0.5}>
                  <SquareIconColored color={datum.color} width={10} height={10} />
                  {datum.dataKey.startsWith('breakdown') && (
                    <TableRowsIcon style={{ fontSize: 12 }} />
                  )}
                  <Typography style={{ fontSize: 12, margin: 0 }}>
                    {`${shortenString(datum.name, 64)}`}
                  </Typography>
                  <div style={{ flexGrow: 1 }} />
                  <Typography
                    key={datum.dataKey}
                    variant='numeric'
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      margin: 0,
                    }}
                  >
                    {getDisplayValueByFormat(
                      datum.payload[datum.dataKey],
                      useStack100 ? 'percent' : metricItem.format,
                      {
                        resolution,
                        timezone: TIMEZONE,
                        decimalCount: 2,
                        hideIntegerDecimals: true,
                        abbreviateLargeAmounts: true,
                        currency: userDoc.currency,
                      }
                    )}
                  </Typography>
                </Box>
                {index < payload.length - 1 && <Box sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }} />}
              </React.Fragment>
            )
          })}
        </Box>
      </Box>
    )
  }

  return null
}

const CustomReferenceLineLabel = (props) => {
  const { viewBox, offset, label, type, stroke } = props

  const theme = useTheme()
  const labelWidthPadding = label && label.length < 5 ? 16 : 12
  const labelWidth = label ? label.length * 8 + labelWidthPadding : 54
  const labelHeight = 20

  let x
  let y
  let textDx
  let textDy
  let textAnchor

  if (type === 'x' || type === 'segment') {
    x = viewBox.x - labelWidth / 2
    y = viewBox.height - labelHeight / 2 - 1
    textDx = labelWidth / 2
    textDy = labelHeight / 2 + offset
    textAnchor = 'middle'
  } else if (type === 'y') {
    x = viewBox.x + 1
    y = viewBox.y - labelHeight / 2
    textDx = offset
    textDy = labelHeight / 2 + offset
    textAnchor = 'start'
  }

  if (!label) return null
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={labelWidth}
        height={labelHeight}
        rx={theme.shape.borderRadius}
        ry={theme.shape.borderRadius}
        fill={lighten(stroke, 0.8)}
      />
      <text
        x={x}
        y={y}
        fill={theme.palette.getContrastText(lighten(stroke, 0.8))}
        textAnchor={textAnchor}
      >
        <tspan
          dx={textDx}
          dy={textDy}
        >
          {label}
        </tspan>
      </text>
    </g>
  )
}

Chart.propTypes = {
  parentKey: PropTypes.string.isRequired,
  xKey: PropTypes.string.isRequired,
  xKeyAsRange: PropTypes.string,
  emptyDataRule: PropTypes.string.isRequired,
  allowSecondaryChart: PropTypes.bool,
  allowLineDots: PropTypes.bool,
  allowUseStack100: PropTypes.bool,
  hideYValuesAfterToday: PropTypes.bool,
  metricItem: PropTypes.object.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  resolution: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  showCumulative: PropTypes.bool,
  showSecondaryChart: PropTypes.bool,
  segments: PropTypes.array.isRequired,
  segmentsData: PropTypes.array.isRequired,
  segmentsBreakdownData: PropTypes.array.isRequired,
  segmentsEligibleBreakdownKeys: PropTypes.array.isRequired,
  aliases: PropTypes.object.isRequired,
  chartHoverIndex: PropTypes.number,
  referenceLines: PropTypes.array,
  onResolutionChange: PropTypes.func.isRequired,
  onShowCumulativeChange: PropTypes.func.isRequired,
  onShowSecondaryChartChange: PropTypes.func,
  onChartHoverIndexChange: PropTypes.func.isRequired,
}

export default Chart
